import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.9055 12C20.9055 13.1819 20.6727 14.3522 20.2204 15.4442C19.7681 16.5361 19.1052 17.5282 18.2695 18.364C17.4338 19.1997 16.4416 19.8626 15.3497 20.3149C14.2577 20.7672 13.0874 21 11.9055 21C10.7236 21 9.5533 20.7672 8.46137 20.3149C7.36944 19.8626 6.37728 19.1997 5.54156 18.364C4.70583 17.5282 4.04289 16.5361 3.5906 15.4442C3.13831 14.3522 2.90552 13.1819 2.90552 12C2.90552 9.61305 3.85373 7.32387 5.54156 5.63604C7.22938 3.94821 9.51857 3 11.9055 3C14.2925 3 16.5817 3.94821 18.2695 5.63604C19.9573 7.32387 20.9055 9.61305 20.9055 12ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V12C11.25 12.1989 11.329 12.3897 11.4697 12.5303L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L12.75 11.6893V8Z"
			fill={color}
		/>
	</svg>
)

export default icon
