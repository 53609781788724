'use client'

import { FC, ReactNode, useId } from 'react'
import { ImgProps } from './Img'
import { Button } from 'components/Phantom/Button'

interface InteractiveImgProps {
	imgProps: ImgProps
	sources: ReactNode[]
	simpleImage: ReactNode
}

export const InteractiveImg: FC<InteractiveImgProps> = (props) => {
	const { imgProps, sources, simpleImage } = props

	const id = useId()

	return (
		<Button.Empty
			id={id}
			onClick={imgProps.onClick}
			className={imgProps.className}
			href={imgProps.href}
		>
			<picture>
				{sources}
				{simpleImage}
			</picture>
		</Button.Empty>
	)
}
