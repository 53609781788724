import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 3.625C9.77881 3.625 7.6486 4.50736 6.07798 6.07798C4.50736 7.6486 3.625 9.77881 3.625 12C3.625 13.0998 3.84163 14.1889 4.26251 15.205C4.68339 16.2211 5.30029 17.1443 6.07798 17.922C6.85567 18.6997 7.77892 19.3166 8.79503 19.7375C9.81113 20.1584 10.9002 20.375 12 20.375C13.0998 20.375 14.1889 20.1584 15.205 19.7375C16.2211 19.3166 17.1443 18.6997 17.922 17.922C18.6997 17.1443 19.3166 16.2211 19.7375 15.205C20.1584 14.1889 20.375 13.0998 20.375 12C20.375 9.77881 19.4926 7.6486 17.922 6.07798C16.3514 4.50736 14.2212 3.625 12 3.625ZM5.1941 5.1941C6.99913 3.38906 9.44729 2.375 12 2.375C14.5527 2.375 17.0009 3.38906 18.8059 5.1941C20.6109 6.99914 21.625 9.44729 21.625 12C21.625 13.264 21.376 14.5156 20.8923 15.6833C20.4086 16.8511 19.6997 17.9121 18.8059 18.8059C17.9121 19.6997 16.8511 20.4086 15.6833 20.8923C14.5156 21.376 13.264 21.625 12 21.625C10.736 21.625 9.48443 21.376 8.31667 20.8923C7.14891 20.4086 6.08786 19.6997 5.1941 18.8059C4.30033 17.9121 3.59136 16.8511 3.10766 15.6833C2.62396 14.5156 2.375 13.264 2.375 12C2.375 9.44729 3.38906 6.99914 5.1941 5.1941Z"
			fill="black"
			stroke={color}
			strokeWidth="0.3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.791 10.71L10.441 11.52L9.82102 10.49L12.031 9.09H13.221V16H11.791V10.71Z"
			fill="black"
		/>
	</svg>
)

export default icon
