import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M15.5305 10.2301C16.3342 9.43583 16.7857 8.35856 16.7857 7.23529C16.7857 6.11202 16.3342 5.03476 15.5305 4.24049C14.7267 3.44622 13.6366 3 12.5 3C11.3634 3 10.2733 3.44622 9.46954 4.24049C8.66581 5.03476 8.21429 6.11202 8.21429 7.23529C8.21429 8.35856 8.66581 9.43583 9.46954 10.2301C10.2733 11.0244 11.3634 11.4706 12.5 11.4706C13.6366 11.4706 14.7267 11.0244 15.5305 10.2301Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.1967 15.7591C8.60322 14.3691 10.5109 13.5882 12.5 13.5882C14.4891 13.5882 16.3968 14.3691 17.8033 15.7591C19.2098 17.1491 20 19.0343 20 21H5C5 19.0343 5.79018 17.1491 7.1967 15.7591Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
