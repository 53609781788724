import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M3.39682 3.58579C3.02175 3.96086 2.81104 4.46957 2.81104 5V6C2.81104 14.284 9.52703 21 17.811 21H18.811C19.3415 21 19.8502 20.7893 20.2252 20.4142C20.6003 20.0391 20.811 19.5304 20.811 19V15.721C20.8111 15.511 20.745 15.3064 20.6223 15.136C20.4995 14.9657 20.3262 14.8383 20.127 14.772L15.634 13.274C15.4057 13.1981 15.1576 13.2071 14.9354 13.2993C14.7131 13.3915 14.5316 13.5607 14.424 13.776L14.1545 14.3144C13.6738 15.2744 12.5071 15.6984 11.62 15.0936C10.4797 14.3161 9.49499 13.3313 8.71746 12.191C8.11263 11.304 8.5366 10.1372 9.49661 9.65657L10.035 9.387C10.2503 9.27945 10.4195 9.0979 10.5118 8.87564C10.604 8.65339 10.6129 8.40534 10.537 8.177L9.03903 3.684C8.97274 3.48496 8.84552 3.3118 8.67539 3.18905C8.50526 3.0663 8.30083 3.00016 8.09103 3H4.81104C4.2806 3 3.77189 3.21071 3.39682 3.58579Z"
			stroke={color}
			strokeWidth="1.5"
		/>
		<path
			d="M12.811 6.03674C14.3 6.21988 17.3656 7.45738 17.7165 10.9423"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M13.5117 3C15.5524 3.22692 19.8576 5.13306 20.7532 10.9423"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default icon
