const S3CDN = 'eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets'
const IMGIX_CDN = 'eightsleep.imgix.net'
const CLOUDINARY_CDN = 'res.cloudinary.com'
const DEVICE_PIXEL_RATIOS = [1, 2, 3, 4]

const addImgixParameters = (src: string, doNotCompress: boolean) => {
	let newSrc = src + `?auto=format`
	if (!doNotCompress) {
		newSrc = newSrc + `,compress`
	}
	return newSrc
}

export const srcToImgix = (src: string, doNotCompress = false): string => {
	let cutSrc = src.split('?v')[0] // Remove version from shopify url
	if (cutSrc.includes('.svg')) return cutSrc
	if (src.includes(S3CDN)) cutSrc = cutSrc.replace(S3CDN, IMGIX_CDN)
	if (cutSrc.includes('imgix.net')) return addImgixParameters(cutSrc, doNotCompress)
	return cutSrc
}

export const srcToCloudinary = (src: string, doNotCompress = false, dims: { width: number; height: number }, dpr = 1) => {
	const split = src.split('/')

	const item = split.pop()
	if (dims) {
		/**
		 * Explanation of parameters:
		 * c_fill: Fill the image to the dimensions
		 * g_auto: Sets gravity crop to auto. If a custom region is set, the gravity will be towards that region.
		 * w_${dims.width},h_${dims.height}: Set the width and height of the image
		 * f_auto: Auto format the image
		 * q_80: Set the quality of the image to 80% (we should replace this with auto quality if we get access)
		 * dpr_${dpr.toFixed(1)}: Set the dpr of the image
		 */

		const params = ['c_fill', 'g_auto', 'f_auto', `dpr_${dpr.toFixed(1)}`]

		if (dims?.width) {
			params.push(`w_${dims.width}`)
		}

		if (dims?.height) {
			params.push(`h_${dims.height}`)
		}

		if (!doNotCompress) {
			params.push('q_80')
		}

		split.push(params.join(','))
	}

	return `${split.join('/')}/${item}`
}

export const srcToImageCDN = (src: string, doNotCompress = false, dims: { width: number; height: number }) => {
	if (src.includes(CLOUDINARY_CDN)) return srcToCloudinary(src, doNotCompress, dims)
	return srcToImgix(src, doNotCompress)
}

/**
 * Generates a srcSet string for a <source> or <img> where the input src is converted into an imgix
 * link, and appended with url params for each dpr ratio and the dpr breakpoint height.
 * @param src Input image url. If this isn't an 8S Shopify url, the function will return an empty srcSet.
 * @param dprHeight DPR height breakpoint for the image.
 * @param doNotCompress Flag to disable imgix compression
 */
export const getSrcSet = (src: string, dims: { width: number; height: number }, doNotCompress: boolean) => {
	if (src.includes('.svg') || (!src.includes(S3CDN) && !src.includes(IMGIX_CDN) && !src.includes(CLOUDINARY_CDN))) return ''
	if (src.includes(CLOUDINARY_CDN)) {
		const sources = []

		for (const dpr of DEVICE_PIXEL_RATIOS) {
			sources.push(srcToCloudinary(src, doNotCompress, dims, dpr) + ` ${dpr}x`)
		}

		return sources.join(',\n')
	}

	const sources = []

	for (const dpr of DEVICE_PIXEL_RATIOS) {
		sources.push(srcToImageCDN(src, doNotCompress, dims) + `&h=${dims.height}&dpr=${dpr} ${dpr}x`)
	}

	return sources.join(',\n')
}
