import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12 3C14.3567 5.15313 17.4561 6.26635 20.618 6.09537C20.867 7.07693 21 8.10533 21 9.16631C21 14.8602 17.176 19.6437 12 21C6.824 19.6437 3 14.8591 3 9.16631C2.99918 8.13033 3.12754 7.09843 3.382 6.09537C6.5439 6.26635 9.64327 5.15313 12 3Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 12.2209L11 14.2574L15 10.1845"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
