import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<ellipse
			cx="19"
			cy="16.5"
			rx="2"
			ry="2.5"
			fill={color}
		/>
		<ellipse
			cx="5"
			cy="16.5"
			rx="2"
			ry="2.5"
			fill={color}
		/>
		<ellipse
			cx="12"
			cy="7.5"
			rx="2"
			ry="2.5"
			fill={color}
		/>
		<path
			d="M18.75 14.25V3V14.25Z"
			fill={color}
		/>
		<path
			d="M12 5.25V3M12 5.25C11.4033 5.25 10.831 5.48705 10.409 5.90901C9.98705 6.33097 9.75 6.90326 9.75 7.5C9.75 8.09674 9.98705 8.66903 10.409 9.09099C10.831 9.51295 11.4033 9.75 12 9.75M12 5.25C12.5967 5.25 13.169 5.48705 13.591 5.90901C14.0129 6.33097 14.25 6.90326 14.25 7.5C14.25 8.09674 14.0129 8.66903 13.591 9.09099C13.169 9.51295 12.5967 9.75 12 9.75M12 9.75V21M5.25 18.75C5.84674 18.75 6.41903 18.5129 6.84099 18.091C7.26295 17.669 7.5 17.0967 7.5 16.5C7.5 15.9033 7.26295 15.331 6.84099 14.909C6.41903 14.4871 5.84674 14.25 5.25 14.25M5.25 18.75C4.65326 18.75 4.08097 18.5129 3.65901 18.091C3.23705 17.669 3 17.0967 3 16.5C3 15.9033 3.23705 15.331 3.65901 14.909C4.08097 14.4871 4.65326 14.25 5.25 14.25M5.25 18.75V21M5.25 14.25V3M18.75 18.75C19.3467 18.75 19.919 18.5129 20.341 18.091C20.7629 17.669 21 17.0967 21 16.5C21 15.9033 20.7629 15.331 20.341 14.909C19.919 14.4871 19.3467 14.25 18.75 14.25M18.75 18.75C18.1533 18.75 17.581 18.5129 17.159 18.091C16.7371 17.669 16.5 17.0967 16.5 16.5C16.5 15.9033 16.7371 15.331 17.159 14.909C17.581 14.4871 18.1533 14.25 18.75 14.25M18.75 18.75V21M18.75 14.25V3"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
