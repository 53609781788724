import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12.2231 8.44598V9.98185V15.9686"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.69092 12.2073L10.1331 12.2073L15.7547 12.2073"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.69727 9.51642L13.6723 13.7497L14.6921 14.8357"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.6917 9.51642L10.7166 13.7497L9.69683 14.8357"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="3.75"
			y="5.75"
			width="16.5"
			height="12.5"
			rx="1.25"
			stroke={color}
			strokeWidth="1.5"
		/>
	</svg>
)

export default icon
