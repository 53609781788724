import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V8.90136L13.5262 13.8839L13.526 13.884C13.0741 14.1855 12.5431 14.3463 11.9999 14.3463C11.4568 14.3463 10.9258 14.1855 10.4739 13.884L10.4737 13.8839L3 8.90143V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19ZM3 7.24993C3.14316 7.2498 3.28792 7.2906 3.41597 7.37597L11.306 12.636L11.3062 12.6361C11.5116 12.7732 11.753 12.8463 11.9999 12.8463C12.2469 12.8463 12.4883 12.7732 12.6937 12.6361L12.6939 12.636L20.5839 7.37597C20.712 7.29058 20.8568 7.24978 21 7.24993V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V7.24993Z"
			fill={color}
		/>
	</svg>
)

export default icon
