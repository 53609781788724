'use client'

import Link, { LinkProps } from 'next/link'
import { useParams } from 'next/navigation'
import { FC, PropsWithChildren } from 'react'
import { UrlObject } from 'url'
import { getSafeLocale, RegionShort } from 'utils/internationalization'

const injectRegionIntoPath = (path: string | UrlObject, region?: RegionShort): LinkProps['href'] => {
	if (region === undefined || path === '' || region === 'us') {
		return path
	}

	if (typeof window !== 'undefined' && window.location.host.endsWith('.com.mx') && region === 'mx') {
		return path
	}

	if (typeof path === 'string') {
		if (path.indexOf('http') !== -1) {
			return path
		}
		return `${region ? '/' + region : ''}${path}`
	}

	if (path instanceof URL) {
		path.pathname = `${region ? '/' + region : ''}${path.toString()}`
	}

	return path
}

export const LinkWithLocale: FC<PropsWithChildren<LinkProps>> = (props) => {
	const params = useParams()
	const locale = getSafeLocale(params as Record<string, string>)
	const path = injectRegionIntoPath(props.href, locale)

	return (
		<Link
			{...props}
			href={path}
			passHref
		>
			{props.children}
		</Link>
	)
}

export default LinkWithLocale
