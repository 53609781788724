import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M2.10596 10.0591L8.60126 14.9725C9.296 15.498 10.1433 15.7824 11.0144 15.7824H21.894"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M5.12512 8.21762L8.37487 10.7335"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default icon
