import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.4524 1C13.2789 1 12.3276 1.95133 12.3276 3.12486V3.24124C12.3276 4.41477 13.2789 5.3661 14.4524 5.3661C15.626 5.3661 16.5773 4.41477 16.5773 3.24124V3.12486C16.5773 1.95133 15.626 1 14.4524 1ZM12.9082 5.62127C12.7141 5.38199 12.3934 5.28575 12.0996 5.37862L7.49576 6.83398C7.25724 6.90939 7.07201 7.09875 7.00189 7.33888L5.93946 10.9773C5.82336 11.3749 6.05156 11.7914 6.44917 11.9075C6.84678 12.0236 7.26323 11.7954 7.37933 11.3977L8.32956 8.14357L9.80493 7.67718V12.0064C9.68638 12.0804 9.5898 12.1868 9.52762 12.3145L6.76613 17.9888L3.35119 20.7955C3.0312 21.0585 2.985 21.5311 3.24801 21.8511C3.51102 22.1711 3.98364 22.2173 4.30363 21.9543L7.84507 19.0436C7.92842 18.9751 7.99601 18.8894 8.04323 18.7924L10.541 13.66L14.5101 15.6989L11.8506 17.8848C11.5306 18.1478 11.4844 18.6204 11.7474 18.9404C12.0105 19.2604 12.4831 19.3066 12.8031 19.0436L16.3445 16.1329C16.5396 15.9725 16.6409 15.7247 16.614 15.4736C16.5871 15.2225 16.4356 15.0017 16.211 14.8863L12.4148 12.9362C12.4414 12.8735 12.4598 12.8063 12.4686 12.7357L13.0125 8.38288C13.024 8.29097 13.0183 8.19921 12.9969 8.11179L15.2846 10.9323C15.5221 11.225 15.9398 11.2961 16.2607 11.0983L19.8021 8.91523C20.1547 8.69788 20.2644 8.23583 20.047 7.88323C19.8296 7.53063 19.3676 7.42099 19.015 7.63834L16.0345 9.47562L12.9082 5.62127ZM12.5145 7.5171L12.0649 6.96277L11.9123 7.01099L12.5145 7.5171ZM11.3049 9.95099V8.45993L11.4735 8.60163L11.3049 9.95099Z"
			fill={color}
		/>
	</svg>
)

export default icon
