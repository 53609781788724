import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.7143 4.28571H12.0001C12.439 4.28571 12.8778 4.45285 13.2121 4.788L19.2123 10.7881C19.5337 11.1096 19.7142 11.5456 19.7142 12.0002C19.7142 12.4548 19.5337 12.8907 19.2123 13.2122L13.2121 19.2123C12.8907 19.5337 12.4547 19.7143 12.0001 19.7143C11.5455 19.7143 11.1096 19.5337 10.7881 19.2123L4.78794 13.2122C4.62852 13.0532 4.50207 12.8643 4.41587 12.6563C4.32967 12.4483 4.28541 12.2253 4.28565 12.0002V7.71436C4.28565 6.80502 4.64688 5.93293 5.28988 5.28993C5.93287 4.64694 6.80496 4.28571 7.7143 4.28571ZM7.49994 8C7.77608 8 7.99994 7.77614 7.99994 7.5C7.99994 7.22386 7.77608 7 7.49994 7C7.2238 7 6.99994 7.22386 6.99994 7.5C6.99994 7.77614 7.2238 8 7.49994 8Z"
			fill={color}
		/>
	</svg>
)

export default icon
