import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 3H8C8.91309 3 9.794 3.3122 10.5 3.87748V19.1439C10.0478 18.5866 9.53117 18 9 18H2V3ZM13.5 19.144C13.9522 18.5867 14.4689 18 15.0001 18H22.0001V3H16.0001C15.087 3 14.206 3.31223 13.5 3.87758V19.144Z"
			fill={color}
		/>
		<path
			d="M2 3V2.25C1.58579 2.25 1.25 2.58579 1.25 3H2ZM10.5 3.87748H11.25C11.25 3.64975 11.1465 3.43436 10.9688 3.29203L10.5 3.87748ZM10.5 19.1439L9.91758 19.6164C10.1177 19.863 10.4514 19.9569 10.7507 19.8508C11.05 19.7446 11.25 19.4615 11.25 19.1439H10.5ZM2 18H1.25C1.25 18.4142 1.58579 18.75 2 18.75V18ZM13.5 19.144H12.75C12.75 19.4616 12.95 19.7448 13.2493 19.8509C13.5486 19.9571 13.8823 19.8632 14.0824 19.6166L13.5 19.144ZM22.0001 18V18.75C22.4143 18.75 22.7501 18.4142 22.7501 18H22.0001ZM22.0001 3H22.7501C22.7501 2.58579 22.4143 2.25 22.0001 2.25V3ZM13.5 3.87758L13.0312 3.29214C12.8535 3.43448 12.75 3.64986 12.75 3.87758H13.5ZM8 2.25H2V3.75H8V2.25ZM10.9688 3.29203C10.1304 2.62074 9.08432 2.25 8 2.25V3.75C8.74186 3.75 9.45762 4.00365 10.0312 4.46294L10.9688 3.29203ZM11.25 19.1439V3.87748H9.75V19.1439H11.25ZM9 18.75C8.99402 18.75 9.08298 18.7534 9.29122 18.9321C9.48645 19.0996 9.69114 19.3373 9.91758 19.6164L11.0824 18.6714C10.8567 18.3931 10.577 18.0589 10.268 17.7938C9.97211 17.5398 9.53715 17.25 9 17.25V18.75ZM2 18.75H9V17.25H2V18.75ZM1.25 3V18H2.75V3H1.25ZM15.0001 17.25C14.4629 17.25 14.0279 17.5399 13.732 17.7938C13.4231 18.059 13.1433 18.3933 12.9176 18.6715L14.0824 19.6166C14.3089 19.3374 14.5136 19.0997 14.7088 18.9322C14.9171 18.7534 15.0061 18.75 15.0001 18.75V17.25ZM22.0001 17.25H15.0001V18.75H22.0001V17.25ZM21.2501 3V18H22.7501V3H21.2501ZM16.0001 3.75H22.0001V2.25H16.0001V3.75ZM13.9688 4.46302C14.5424 4.00368 15.2582 3.75 16.0001 3.75V2.25C14.9157 2.25 13.8696 2.62079 13.0312 3.29214L13.9688 4.46302ZM12.75 3.87758V19.144H14.25V3.87758H12.75Z"
			fill={color}
		/>
	</svg>
)

export default icon
