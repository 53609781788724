import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.45831 7.05901C8.76665 7.26503 9.12916 7.375 9.5 7.375H11.375V5.5C11.375 5.12916 11.265 4.76665 11.059 4.45831C10.853 4.14996 10.5601 3.90964 10.2175 3.76773C9.87492 3.62581 9.49792 3.58868 9.13421 3.66103C8.77049 3.73338 8.4364 3.91195 8.17417 4.17418C7.91195 4.4364 7.73337 4.77049 7.66103 5.13421C7.58868 5.49792 7.62581 5.87492 7.76773 6.21753C7.90964 6.56014 8.14996 6.85298 8.45831 7.05901ZM5 8.625H11.375V11.375H5C4.63533 11.375 4.28559 11.2301 4.02773 10.9723C3.76987 10.7144 3.625 10.3647 3.625 10C3.625 9.63533 3.76987 9.28559 4.02773 9.02773C4.28559 8.76987 4.63533 8.625 5 8.625ZM19 8.625H12.625V11.375H19C19.3647 11.375 19.7144 11.2301 19.9723 10.9723C20.2301 10.7144 20.375 10.3647 20.375 10C20.375 9.63533 20.2301 9.28559 19.9723 9.02773C19.7144 8.76987 19.3647 8.625 19 8.625ZM5.625 12.625H11.375V20.375H7C6.63533 20.375 6.28559 20.2301 6.02773 19.9723C5.76987 19.7144 5.625 19.3647 5.625 19V12.625ZM12.625 12.625V20.375H17C17.3647 20.375 17.7144 20.2301 17.9723 19.9723C18.2301 19.7144 18.375 19.3647 18.375 19V12.625H12.625ZM14.7639 7.14327C14.5378 7.29436 14.2719 7.375 14 7.375H12.625V6C12.625 5.72805 12.7056 5.46221 12.8567 5.23609C13.0078 5.00997 13.2226 4.83374 13.4738 4.72967C13.7251 4.6256 14.0015 4.59837 14.2682 4.65142C14.535 4.70448 14.78 4.83543 14.9723 5.02773C15.1646 5.22003 15.2955 5.46503 15.3486 5.73175C15.4016 5.99847 15.3744 6.27494 15.2703 6.52619C15.1663 6.77744 14.99 6.99218 14.7639 7.14327Z"
			fill={color}
		/>
	</svg>
)

export default icon
