import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M16.9458 4L13.1359 3.06C12.9751 3.02025 12.8101 3.0001 12.6444 3H8.57302C8.19659 3.00001 7.8276 3.10349 7.50742 3.29884C7.18724 3.49419 6.92851 3.77369 6.76025 4.106L3.21374 11.106C3.05934 11.4109 2.98648 11.7497 3.00206 12.0902C3.01765 12.4308 3.12116 12.7617 3.30278 13.0516C3.48439 13.3416 3.73808 13.5809 4.03977 13.7469C4.34145 13.9129 4.68111 14 5.02651 14H9.85382H11.8804M16.9458 4L16.9468 13L13.5098 18.088C13.1075 18.683 12.8937 19.381 12.8937 20.096C12.8937 20.595 12.4833 21 11.9767 21H11.8794C11.3419 21 10.8264 20.7893 10.4464 20.4142C10.0663 20.0391 9.85281 19.5304 9.85281 19V14M16.9458 4H18.9734C19.5109 4 20.0264 4.21071 20.4064 4.58579C20.7865 4.96086 21 5.46957 21 6V12C21 12.5304 20.7865 13.0391 20.4064 13.4142C20.0264 13.7893 19.5109 14 18.9734 14H16.4402"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
