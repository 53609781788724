import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M20.3149 15.4441C20.7672 14.3522 21 13.1818 21 11.9999C21 9.61299 20.0518 7.32381 18.364 5.63598C16.6761 3.94815 14.3869 2.99994 12 2.99994C9.61305 2.99994 7.32387 3.94815 5.63604 5.63598C3.94821 7.32381 3 9.61299 3 11.9999C3 13.1818 3.23279 14.3522 3.68508 15.4441C4.13738 16.536 4.80031 17.5282 5.63604 18.3639C6.47177 19.1996 7.46392 19.8626 8.55585 20.3149C9.64778 20.7671 10.8181 20.9999 12 20.9999C13.1819 20.9999 14.3522 20.7671 15.4442 20.3149C16.5361 19.8626 17.5282 19.1996 18.364 18.3639C19.1997 17.5282 19.8626 16.536 20.3149 15.4441Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 8.99994V14.9999M14 8.99994V14.9999"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
