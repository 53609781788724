import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M1.99988 10.9677L8.18744 14.4703C9.07246 14.9713 10.1151 15.1163 11.1032 14.876L16.4272 13.581C16.7367 13.5058 17.0541 13.4677 17.3726 13.4677H22.0001"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M4.02209 8.74994L7.2901 10.7013"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default icon
