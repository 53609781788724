import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M3 16.5V17.625C3 18.5201 3.35558 19.3786 3.98851 20.0115C4.62145 20.6444 5.47989 21 6.375 21H17.625C18.5201 21 19.3786 20.6444 20.0115 20.0115C20.6444 19.3786 21 18.5201 21 17.625V16.5"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.5 7.5L12 3M12 3L7.5 7.5M12 3V14.8125"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
