import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.5886 4.00006C13.7084 4.00006 12.9949 4.71356 12.9949 5.59371V5.68099C12.9949 6.56114 13.7084 7.27464 14.5886 7.27464C15.4687 7.27464 16.1822 6.56114 16.1822 5.68099V5.59371C16.1822 4.71356 15.4687 4.00006 14.5886 4.00006ZM13.5761 7.34793C13.382 7.10864 13.0613 7.0124 12.7675 7.10527L9.31463 8.19679C9.07611 8.2722 8.89088 8.46156 8.82076 8.70169L8.02394 11.4305C7.90783 11.8281 8.13604 12.2446 8.53365 12.3607C8.93126 12.4768 9.3477 12.2486 9.46381 11.851L10.1484 9.50638L10.9155 9.26389V12.1603C10.8371 12.2269 10.7724 12.3096 10.7265 12.4039L8.67325 16.6228L6.14367 18.7019C5.82368 18.9649 5.77748 19.4375 6.04049 19.7575C6.3035 20.0775 6.77612 20.1237 7.09612 19.8607L9.75219 17.6776C9.83554 17.6091 9.90313 17.5235 9.95035 17.4264L11.7398 13.7494L14.2924 15.0607L12.5183 16.5188C12.1983 16.7818 12.1521 17.2545 12.4151 17.5745C12.6781 17.8945 13.1507 17.9407 13.4707 17.6776L16.1268 15.4946C16.3219 15.3342 16.4232 15.0864 16.3963 14.8353C16.3694 14.5842 16.2179 14.3635 15.9933 14.2481L13.2824 12.8555C13.2841 12.8455 13.2856 12.8353 13.2868 12.8251L13.6607 9.83337L15.0672 11.5674C15.3046 11.8601 15.7224 11.9312 16.0432 11.7334L18.6993 10.0961C19.0519 9.87877 19.1615 9.41672 18.9442 9.06412C18.7268 8.71152 18.2648 8.60188 17.9122 8.81923L15.817 10.1107L13.5761 7.34793Z"
			fill={color}
		/>
	</svg>
)

export default icon
