import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 2.25C10.7402 2.25 9.53204 2.75044 8.64124 3.64124C7.75044 4.53204 7.25 5.74022 7.25 7V11H6C5.46957 11 4.96086 11.2107 4.58579 11.5858C4.21071 11.9609 4 12.4696 4 13V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V13C20 12.4696 19.7893 11.9609 19.4142 11.5858C19.0391 11.2107 18.5304 11 18 11H16.75V7C16.75 5.74022 16.2496 4.53204 15.3588 3.64124C14.468 2.75044 13.2598 2.25 12 2.25ZM9.7019 4.7019C10.3114 4.09241 11.138 3.75 12 3.75C12.862 3.75 13.6886 4.09241 14.2981 4.7019C14.9076 5.3114 15.25 6.13805 15.25 7V10.75H8.75V7C8.75 6.13805 9.09241 5.3114 9.7019 4.7019ZM12 14.25C12.4142 14.25 12.75 14.5858 12.75 15V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V15C11.25 14.5858 11.5858 14.25 12 14.25Z"
			fill={color}
		/>
	</svg>
)

export default icon
