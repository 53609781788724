import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M18.4286 3H5.57143C4.15127 3 3 3.80589 3 4.8V19.2C3 20.1941 4.15127 21 5.57143 21H18.4286C19.8487 21 21 20.1941 21 19.2V4.8C21 3.80589 19.8487 3 18.4286 3Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<line
			x1="12.25"
			y1="21"
			x2="12.25"
			y2="3"
			stroke={color}
			strokeWidth="1.5"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.53226 8.92955C7.53226 9.10013 7.38482 9.23845 7.20301 9.23845H6.28487C6.10305 9.23845 5.95571 9.10013 5.95571 8.92955V8.35629C5.95571 8.18571 6.10305 8.04738 6.28487 8.04738H7.20301C7.38482 8.04738 7.53226 8.18571 7.53226 8.35629V8.92955ZM5.95571 6.06869C5.95571 5.89802 6.10305 5.75979 6.28487 5.75979H7.20301C7.38482 5.75979 7.53226 5.89802 7.53226 6.06869V6.48348C7.53226 6.65415 7.38482 6.79239 7.20301 6.79239H6.28487C6.10305 6.79239 5.95571 6.65415 5.95571 6.48348V6.06869ZM8.48562 6.51744V5.85528C8.48562 5.38264 8.07776 5 7.57398 5H5.91174C5.40795 5 5 5.38264 5 5.85528V6.51744C5 6.86561 5.22033 7.16222 5.53639 7.29621V7.41864C5.22033 7.55262 5 7.85108 5 8.19741V9.14463C5 9.61727 5.40795 10 5.91174 10H7.57398C8.07776 10 8.48562 9.61727 8.48562 9.14463V8.19741C8.48562 7.85108 8.26539 7.55262 7.94923 7.41864V7.29621C8.26539 7.16222 8.48562 6.86376 8.48562 6.51744Z"
			fill="black"
		/>
	</svg>
)

export default icon
