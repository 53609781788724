import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12.0001 19C12.9461 19 13.8101 18.897 14.5981 18.719L12.8411 16.962C12.5681 16.983 12.2911 17 12.0001 17C6.6491 17 4.5761 13.154 4.0741 12C4.45105 11.1588 4.96015 10.3833 5.5821 9.70297L4.1841 8.30497C2.6461 9.97197 2.0631 11.651 2.0521 11.684C1.98312 11.8893 1.98312 12.1116 2.0521 12.317C2.0731 12.383 4.3671 19 12.0001 19ZM12.0001 4.99997C10.1631 4.99997 8.6541 5.39597 7.3961 5.98097L3.7071 2.29297L2.2931 3.70697L20.2931 21.707L21.7071 20.293L18.3881 16.974C21.0021 15.023 21.9351 12.359 21.9491 12.317C22.0181 12.1116 22.0181 11.8893 21.9491 11.684C21.9271 11.617 19.6331 4.99997 12.0001 4.99997ZM16.9721 15.558L14.6921 13.278C14.8821 12.888 15.0001 12.459 15.0001 12C15.0001 10.359 13.6411 8.99997 12.0001 8.99997C11.5411 8.99997 11.1121 9.11797 10.7231 9.30897L8.9151 7.50097C9.90762 7.16038 10.9508 6.99097 12.0001 6.99997C17.3511 6.99997 19.4241 10.846 19.9261 12C19.6241 12.692 18.7601 14.342 16.9721 15.558Z"
			fill={color}
		/>
	</svg>
)

export default icon
