import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.414 20.4295L17.657 16.298C18.7758 15.2085 19.5377 13.8205 19.8463 12.3094C20.155 10.7983 19.9965 9.23209 19.391 7.80871C18.7855 6.38532 17.7601 5.16874 16.4445 4.3128C15.1289 3.45686 13.5822 3 12 3C10.4178 3 8.87107 3.45686 7.55548 4.3128C6.2399 5.16874 5.21452 6.38532 4.60901 7.80871C4.00349 9.23209 3.84504 10.7983 4.15369 12.3094C4.46234 13.8205 5.22422 15.2085 6.343 16.298L10.587 20.4295C10.7726 20.6104 10.9929 20.7538 11.2354 20.8517C11.478 20.9496 11.738 21 12.0005 21C12.263 21 12.523 20.9496 12.7656 20.8517C13.0081 20.7538 13.2284 20.6104 13.414 20.4295ZM15.4286 10.875C15.4286 11.7701 15.0673 12.6285 14.4243 13.2615C13.7814 13.8944 12.9093 14.25 12 14.25C11.0907 14.25 10.2186 13.8944 9.57562 13.2615C8.93263 12.6285 8.57141 11.7701 8.57141 10.875C8.57141 9.97989 8.93263 9.12145 9.57562 8.48851C10.2186 7.85558 11.0907 7.5 12 7.5C12.9093 7.5 13.7814 7.85558 14.4243 8.48851C15.0673 9.12145 15.4286 9.97989 15.4286 10.875Z"
			fill={color}
		/>
	</svg>
)

export default icon
