import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 11.9999C21 13.1818 20.7672 14.3522 20.3149 15.4441C19.8626 16.536 19.1997 17.5282 18.364 18.3639C17.5282 19.1996 16.5361 19.8626 15.4442 20.3149C14.3522 20.7671 13.1819 20.9999 12 20.9999C10.8181 20.9999 9.64778 20.7671 8.55585 20.3149C7.46392 19.8626 6.47177 19.1996 5.63604 18.3639C4.80031 17.5282 4.13738 16.536 3.68508 15.4441C3.23279 14.3522 3 13.1818 3 11.9999C3 9.61299 3.94821 7.32381 5.63604 5.63598C7.32387 3.94815 9.61305 2.99994 12 2.99994C14.3869 2.99994 16.6761 3.94815 18.364 5.63598C20.0518 7.32381 21 9.61299 21 11.9999ZM14.7522 11.1679L11.5552 9.03595C11.4044 8.93538 11.2292 8.87765 11.0482 8.86895C10.8672 8.86025 10.6872 8.9009 10.5275 8.98655C10.3678 9.0722 10.2344 9.19963 10.1415 9.35523C10.0487 9.51082 9.9998 9.68874 10.0002 9.86995V14.1329C10.0002 14.314 10.0493 14.4916 10.1423 14.647C10.2353 14.8023 10.3688 14.9294 10.5284 15.0148C10.688 15.1002 10.8679 15.1407 11.0487 15.1319C11.2295 15.1231 11.4046 15.0654 11.5552 14.9649L14.7522 12.8329C14.8891 12.7416 15.0014 12.6179 15.0791 12.4728C15.1568 12.3276 15.1974 12.1656 15.1974 12.0009C15.1974 11.8363 15.1568 11.6743 15.0791 11.5291C15.0014 11.384 14.8891 11.2593 14.7522 11.1679Z"
			fill={color}
		/>
	</svg>
)

export default icon
