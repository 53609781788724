import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_6423)">
			<path
				d="M16.8 8.59999C16.8 7.32695 16.2943 6.10605 15.3942 5.20588C14.494 4.3057 13.2731 3.79999 12 3.79999C10.727 3.79999 9.50611 4.3057 8.60594 5.20588C7.70576 6.10605 7.20005 7.32695 7.20005 8.59999C7.20005 14.2 4.80005 15.8 4.80005 15.8H19.2C19.2 15.8 16.8 14.2 16.8 8.59999Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.384 19C13.2433 19.2425 13.0414 19.4437 12.7985 19.5836C12.5557 19.7235 12.2803 19.7972 12 19.7972C11.7197 19.7972 11.4443 19.7235 11.2014 19.5836C10.9585 19.4437 10.7566 19.2425 10.616 19"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_6423">
				<rect
					width="16"
					height="17.6"
					fill={color}
					transform="translate(4 3)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
