import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M8.36677 17.2759L7.51416 16.3449C6.88009 15.474 6.50598 14.4014 6.50598 13.2415C6.50598 10.3277 8.86644 7.96558 11.7782 7.96558C14.69 7.96558 17.0505 10.3277 17.0505 13.2415C17.0505 14.4014 16.6763 15.474 16.0423 16.3449L15.1897 17.2759"
			fill={color}
		/>
		<path
			d="M8.36677 17.2759L7.51416 16.3449C6.88009 15.474 6.50598 14.4014 6.50598 13.2415C6.50598 10.3277 8.86644 7.96558 11.7782 7.96558C14.69 7.96558 17.0505 10.3277 17.0505 13.2415C17.0505 14.4014 16.6763 15.474 16.0423 16.3449L15.1897 17.2759"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.77173 17.2759H20.7717H2.77173Z"
			fill={color}
		/>
		<path
			d="M2.77173 17.2759H20.7717"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.25354 21H18.2914H5.25354Z"
			fill={color}
		/>
		<path
			d="M5.25354 21H18.2914"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.7772 3V3.62069V3Z"
			fill={color}
		/>
		<path
			d="M11.7772 3V3.62069"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.2742 4.20575L15.9641 4.74328L16.2742 4.20575Z"
			fill={color}
		/>
		<path
			d="M16.2742 4.20575L15.9641 4.74328"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.5664 7.5L19.0293 7.81035L19.5664 7.5Z"
			fill={color}
		/>
		<path
			d="M19.5664 7.5L19.0293 7.81035"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.7713 12H20.151H20.7713Z"
			fill={color}
		/>
		<path
			d="M20.7713 12H20.151"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.40408 12H2.78381H3.40408Z"
			fill={color}
		/>
		<path
			d="M3.40408 12H2.78381"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.52617 7.81035L3.98901 7.5L4.52617 7.81035Z"
			fill={color}
		/>
		<path
			d="M4.52617 7.81035L3.98901 7.5"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.59175 4.74329L7.28162 4.20575L7.59175 4.74329Z"
			fill={color}
		/>
		<path
			d="M7.59175 4.74329L7.28162 4.20575"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
