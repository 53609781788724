import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7501 8C13.1308 8 14.2501 6.88071 14.2501 5.5C14.2501 4.11929 13.1308 3 11.7501 3C10.3693 3 9.25006 4.11929 9.25006 5.5C9.25006 6.88071 10.3693 8 11.7501 8ZM15.2501 5.5C15.2501 7.433 13.6831 9 11.7501 9C9.81706 9 8.25006 7.433 8.25006 5.5C8.25006 5.32335 8.26315 5.14977 8.2884 4.98015C7.69262 5.0939 7.15674 5.23804 6.69372 5.39239C5.6605 3.50779 3.28442 3.71668 3.66227 3.90561C3.97018 4.05957 4.38929 5.72317 4.56035 6.47157L4.59952 6.45419C3.60179 7.30415 2.91842 8.70536 2.66788 9.42243L1 10.2243V13.4317L2.66788 14.1374C2.92447 15.2407 4.35714 16.9065 5.04139 17.6014V19.9108L7.41491 20.5523C7.54321 20.3385 7.8832 19.7248 8.21677 18.9806C9.52541 19.6221 12.2902 19.419 13.5091 19.2372L14.4072 20.3278L16.941 19.6863L16.6524 17.1845C17.8712 16.2864 19.0415 15.0034 19.4749 12.4374L19.477 12.4253C19.5884 12.4546 19.7083 12.4587 19.8286 12.4322C20.6069 12.261 21.2034 11.905 21.6015 11.4111C21.9974 10.9201 22.155 10.3448 22.147 9.80886C22.139 9.28109 21.9705 8.76453 21.6777 8.3635C21.3878 7.96646 20.9166 7.61397 20.3089 7.61397C19.8947 7.61397 19.5589 7.94975 19.5589 8.36397C19.5589 8.77818 19.8947 9.11397 20.3089 9.11397C20.3096 9.11397 20.3107 9.11399 20.3127 9.11437L20.3165 9.11531C20.3213 9.11671 20.3307 9.12015 20.3447 9.12845C20.3737 9.14574 20.418 9.18194 20.4663 9.24809C20.5658 9.38431 20.6436 9.59584 20.6471 9.83144C20.6506 10.0589 20.5849 10.2822 20.4338 10.4696C20.2889 10.6493 20.0252 10.8457 19.5454 10.9583C19.3157 8.01379 16.8729 6.13693 15.4977 5.50933C15.4147 5.48062 15.3319 5.45282 15.2493 5.42592C15.2498 5.45055 15.2501 5.47525 15.2501 5.5ZM7 10C7 10.5523 6.55228 11 6 11C5.44772 11 5 10.5523 5 10C5 9.44772 5.44772 9 6 9C6.55228 9 7 9.44772 7 10Z"
			fill={color}
		/>
	</svg>
)

export default icon
