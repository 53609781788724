import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M17.3333 15.3548C17.3333 17.9203 14.9455 20 12 20C9.05444 20 6.66663 17.9203 6.66663 15.3548C6.66663 13.3913 9.79104 7.78152 11.2571 5.25744C11.5921 4.68054 12.4078 4.68054 12.7429 5.25744C14.2089 7.78152 17.3333 13.3913 17.3333 15.3548Z"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default icon
