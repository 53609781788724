import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12 3V4M18.364 5.636L17.657 6.343M21 12H20M4 12H3M6.343 6.343L5.636 5.636"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.464 15.536C7.76487 14.8367 7.2888 13.9458 7.09598 12.9759C6.90316 12.006 7.00225 11.0007 7.38073 10.0872C7.75921 9.17364 8.40007 8.39282 9.22229 7.84348C10.0445 7.29413 11.0112 7.00092 12 7.00092C12.9889 7.00092 13.9555 7.29413 14.7777 7.84348C15.5999 8.39282 16.2408 9.17364 16.6193 10.0872C16.9978 11.0007 17.0968 12.006 16.904 12.9759C16.7112 13.9458 16.2351 14.8367 15.536 15.536L14.988 16.083C14.7878 16.2832 14.6141 16.5074 14.4705 16.75H9.52944C9.3867 16.5087 9.21336 16.2843 9.012 16.083L8.464 15.536ZM9.99289 18.25C9.99762 18.3227 10 18.3957 10 18.469V19C10 19.5304 10.2107 20.0391 10.5858 20.4142C10.9609 20.7893 11.4696 21 12 21C12.5304 21 13.0391 20.7893 13.4142 20.4142C13.7893 20.0391 14 19.5304 14 19V18.469C14 18.3958 14.0024 18.3228 14.0071 18.25H9.99289Z"
			fill={color}
		/>
	</svg>
)

export default icon
