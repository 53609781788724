import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.02651 14H9.85382V19C9.85382 19.5304 10.0673 20.0391 10.4474 20.4142C10.8274 20.7893 11.3429 21 11.8804 21H11.9767C12.4833 21 12.8937 20.595 12.8937 20.095C12.8937 19.381 13.1075 18.683 13.5098 18.089L15.75 14.7721V3.70479L13.1359 3.06C12.9748 3.02 12.8096 3 12.6444 3H8.57404C8.19743 2.99983 7.82823 3.10322 7.50785 3.29858C7.18747 3.49394 6.92859 3.77353 6.76025 4.106L3.21374 11.106C3.05935 11.4109 2.98648 11.7497 3.00206 12.0902C3.01765 12.4308 3.12116 12.7617 3.30278 13.0516C3.48439 13.3416 3.73809 13.5809 4.03977 13.7469C4.34145 13.9129 4.68111 14 5.02651 14ZM19 14C20.1046 14 21 13.1046 21 12V6C21 4.89543 20.1046 4 19 4H18C17.4477 4 17 4.44772 17 5V13C17 13.5523 17.4477 14 18 14H19Z"
			fill={color}
		/>
	</svg>
)

export default icon
