import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.6016 4.07472C17.8604 3.75129 17.8079 3.27932 17.4845 3.02054C17.1611 2.76177 16.6891 2.81418 16.4303 3.13761L14.2195 5.9008H4.62354L11.25 14.1461V19.4923H6.984C6.56978 19.4923 6.234 19.8281 6.234 20.2423C6.234 20.6565 6.56978 20.9923 6.984 20.9923H17.016C17.4302 20.9923 17.766 20.6565 17.766 20.2423C17.766 19.8281 17.4302 19.4923 17.016 19.4923H12.75V14.1461L19.3764 5.9008H16.1406L17.6016 4.07472Z"
			fill={color}
		/>
	</svg>
)

export default icon
