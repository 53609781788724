import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 11.9999C21 13.1818 20.7672 14.3522 20.3149 15.4441C19.8626 16.536 19.1997 17.5282 18.364 18.3639C17.5282 19.1996 16.5361 19.8626 15.4442 20.3149C14.3522 20.7671 13.1819 20.9999 12 20.9999C10.8181 20.9999 9.64778 20.7671 8.55585 20.3149C7.46392 19.8626 6.47177 19.1996 5.63604 18.3639C4.80031 17.5282 4.13738 16.536 3.68508 15.4441C3.23279 14.3522 3 13.1818 3 11.9999C3 9.61299 3.94821 7.32381 5.63604 5.63598C7.32387 3.94815 9.61305 2.99994 12 2.99994C14.3869 2.99994 16.6761 3.94815 18.364 5.63598C20.0518 7.32381 21 9.61299 21 11.9999ZM15.5303 10.5303C15.8232 10.2374 15.8232 9.7625 15.5303 9.46961C15.2374 9.17672 14.7626 9.17672 14.4697 9.46961L11 12.9393L9.53033 11.4696C9.23744 11.1767 8.76256 11.1767 8.46967 11.4696C8.17678 11.7625 8.17678 12.2374 8.46967 12.5303L10.4697 14.5303C10.7626 14.8232 11.2374 14.8232 11.5303 14.5303L15.5303 10.5303Z"
			fill={color}
		/>
	</svg>
)

export default icon
