import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M0.999023 12.9146L9.96412 14.1631C10.5758 14.2483 11.1988 14.1907 11.7845 13.9949L15.2241 12.8446C15.6331 12.7078 16.0615 12.6381 16.4927 12.6381H23.001"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M3.19812 9.70737L7.04624 10.3455"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
)

export default icon
