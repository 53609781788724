import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M4.55324 7.38658C4.19901 7.74081 4 8.22126 4 8.72222V17.2222C4 17.7232 4.19901 18.2036 4.55324 18.5579C4.90748 18.9121 5.38792 19.1111 5.88889 19.1111H19.1111C19.6121 19.1111 20.0925 18.9121 20.4468 18.5579C20.801 18.2036 21 17.7232 21 17.2222V8.72222C21 8.22126 20.801 7.74081 20.4468 7.38658C20.0925 7.03234 19.6121 6.83333 19.1111 6.83333H18.2328C17.9219 6.83338 17.6158 6.75669 17.3416 6.61006C17.0675 6.46343 16.8338 6.2514 16.6612 5.99278L15.8943 4.84056C15.7218 4.58194 15.4881 4.36991 15.2139 4.22328C14.9398 4.07665 14.6337 3.99995 14.3228 4H10.6772C10.3663 3.99995 10.0602 4.07665 9.78608 4.22328C9.51193 4.36991 9.27821 4.58194 9.10567 4.84056L8.33878 5.99278C8.16623 6.2514 7.93251 6.46343 7.65837 6.61006C7.38422 6.75669 7.07812 6.83338 6.76722 6.83333H5.88889C5.38792 6.83333 4.90748 7.03234 4.55324 7.38658Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.5036 14.5035C15.0349 13.9721 15.3334 13.2515 15.3334 12.5C15.3334 11.7486 15.0349 11.0279 14.5036 10.4966C13.9722 9.9652 13.2515 9.66669 12.5001 9.66669C11.7486 9.66669 11.028 9.9652 10.4966 10.4966C9.96526 11.0279 9.66675 11.7486 9.66675 12.5C9.66675 13.2515 9.96526 13.9721 10.4966 14.5035C11.028 15.0348 11.7486 15.3334 12.5001 15.3334C13.2515 15.3334 13.9722 15.0348 14.5036 14.5035Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
