import { IconComponentType } from '../../Icon.types'

export const SleepScore: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={color}
	>
		<path
			d="M10.1851 8.33216C10.4507 8.33413 10.7124 8.38338 10.9703 8.47993C11.2282 8.57648 11.4609 8.7341 11.6683 8.95281C11.8777 9.17152 12.0445 9.46707 12.1686 9.83946C12.2946 10.2099 12.3586 10.6709 12.3605 11.2226C12.3605 11.7527 12.3082 12.2246 12.2035 12.6383C12.0988 13.0501 11.9485 13.3979 11.7527 13.6816C11.5588 13.9653 11.3232 14.1811 11.0459 14.3289C10.7687 14.4766 10.4565 14.5505 10.1094 14.5505C9.75462 14.5505 9.43954 14.4796 9.16421 14.3377C8.88888 14.1959 8.66494 13.9998 8.49237 13.7496C8.31981 13.4974 8.2122 13.2077 8.16954 12.8807H9.0566C9.11477 13.1408 9.23304 13.3516 9.41142 13.5132C9.59174 13.6727 9.82442 13.7525 10.1094 13.7525C10.5457 13.7525 10.886 13.5595 11.1303 13.1733C11.3746 12.7851 11.4977 12.2433 11.4997 11.5477H11.4531C11.3523 11.7172 11.2263 11.863 11.075 11.9852C10.9257 12.1073 10.758 12.2019 10.5719 12.2689C10.3857 12.3359 10.187 12.3694 9.97565 12.3694C9.63246 12.3694 9.32029 12.2837 9.03915 12.1122C8.758 11.9408 8.53406 11.7054 8.36731 11.4059C8.20056 11.1064 8.11719 10.7645 8.11719 10.3803C8.11719 9.99807 8.2025 9.65129 8.37313 9.33998C8.54569 9.02867 8.78612 8.78238 9.09441 8.60111C9.40464 8.41787 9.76819 8.32821 10.1851 8.33216ZM10.188 9.10059C9.96111 9.10059 9.75655 9.15773 9.57429 9.27201C9.39397 9.38431 9.25146 9.53702 9.14676 9.73011C9.04206 9.92123 8.98971 10.134 8.98971 10.3685C8.98971 10.603 9.04012 10.8158 9.14094 11.0069C9.24371 11.196 9.38331 11.3468 9.55975 11.4591C9.73813 11.5694 9.94172 11.6246 10.1705 11.6246C10.3411 11.6246 10.5001 11.5911 10.6475 11.5241C10.7949 11.4571 10.9238 11.3645 11.0343 11.2463C11.1448 11.1261 11.2311 10.9901 11.2932 10.8384C11.3552 10.6867 11.3862 10.5271 11.3862 10.3596C11.3862 10.137 11.3339 9.9301 11.2292 9.73897C11.1264 9.54785 10.9849 9.39417 10.8045 9.27792C10.6242 9.1597 10.4187 9.10059 10.188 9.10059Z"
			fill={color}
		/>
		<path
			d="M15.4849 14.5683C15.0254 14.5663 14.6327 14.4431 14.307 14.1988C13.9812 13.9545 13.7321 13.5989 13.5595 13.1319C13.387 12.6649 13.3007 12.1024 13.3007 11.4443C13.3007 10.7882 13.387 10.2276 13.5595 9.76262C13.734 9.29762 13.9842 8.94296 14.3099 8.69864C14.6376 8.45432 15.0292 8.33216 15.4849 8.33216C15.9405 8.33216 16.3312 8.4553 16.657 8.70159C16.9827 8.94591 17.2319 9.30058 17.4044 9.76557C17.5789 10.2286 17.6662 10.7882 17.6662 11.4443C17.6662 12.1044 17.5799 12.6679 17.4073 13.1348C17.2348 13.5998 16.9856 13.9555 16.6599 14.2018C16.3341 14.4461 15.9425 14.5683 15.4849 14.5683ZM15.4849 13.7791C15.8882 13.7791 16.2033 13.5792 16.4301 13.1792C16.6589 12.7792 16.7733 12.2009 16.7733 11.4443C16.7733 10.9419 16.721 10.5173 16.6163 10.1705C16.5135 9.82173 16.3652 9.5577 16.1713 9.3784C15.9793 9.19713 15.7505 9.1065 15.4849 9.1065C15.0835 9.1065 14.7684 9.30747 14.5397 9.70942C14.3109 10.1114 14.1955 10.6897 14.1936 11.4443C14.1936 11.9487 14.2449 12.3753 14.3477 12.724C14.4524 13.0708 14.6007 13.3338 14.7927 13.5132C14.9846 13.6905 15.2154 13.7791 15.4849 13.7791Z"
			fill={color}
		/>
		<path
			d="M22.2855 18.1961C22.4948 18.3314 22.7751 18.2696 22.8993 18.0536C23.8886 16.3329 24.4419 14.3867 24.5058 12.3902C24.5746 10.2418 24.0742 8.11411 23.057 6.22947C22.0399 4.34482 20.5432 2.77241 18.7235 1.67657C16.9038 0.580733 14.8279 0.00170548 12.7126 3.76059e-06C10.5974 -0.00169796 8.52054 0.573989 6.69913 1.6669C4.87772 2.7598 3.37862 4.32981 2.35852 6.21281C1.33842 8.09582 0.834756 10.2227 0.900181 12.3712C0.960983 14.3678 1.51121 16.315 2.49782 18.0372C2.6217 18.2534 2.90189 18.3156 3.11138 18.1806V18.1806C3.3166 18.0484 3.37676 17.7763 3.25581 17.5642C2.35008 15.9762 1.84497 14.1824 1.78897 12.3432C1.72847 10.3565 2.1942 8.38981 3.13749 6.64859C4.08078 4.90738 5.46699 3.4556 7.15125 2.44499C8.8355 1.43438 10.756 0.902041 12.7119 0.903615C14.6679 0.905188 16.5875 1.44061 18.2702 2.45393C19.9529 3.46725 21.3368 4.92126 22.2774 6.66399C23.218 8.40672 23.6806 10.3742 23.617 12.3608C23.5582 14.1999 23.0503 15.9929 22.1421 17.5794C22.0208 17.7913 22.0805 18.0635 22.2855 18.1961V18.1961Z"
			fill={color}
			fillOpacity="0.15"
		/>
		<path
			d="M23.6332 8.8574C23.8728 8.78851 24.0129 8.53902 23.9372 8.30148C23.4614 6.80911 22.7017 5.42455 21.6999 4.22716C20.6022 2.91514 19.2395 1.85859 17.7043 1.12927C16.1691 0.399947 14.4975 0.0149483 12.8029 0.000426648C11.1082 -0.014095 9.43042 0.3422 7.88335 1.04511C6.33628 1.74801 4.95621 2.78107 3.83689 4.07408C2.71758 5.3671 1.88524 6.88979 1.39643 8.53871C0.90762 10.1876 0.773784 11.9241 1.00402 13.6303C1.21354 15.1829 1.7201 16.6773 2.49355 18.0297C2.61977 18.2504 2.90564 18.3138 3.11935 18.1761V18.1761C3.32871 18.0412 3.39007 17.7636 3.26685 17.5472C2.55903 16.3038 2.0952 14.931 1.90278 13.505C1.69023 11.93 1.81379 10.3269 2.26505 8.80461C2.71631 7.28237 3.4847 5.87665 4.51803 4.68297C5.55135 3.48928 6.8254 2.53559 8.25363 1.88669C9.68185 1.23778 11.2308 0.908853 12.7952 0.922259C14.3596 0.935665 15.9029 1.29109 17.3201 1.96438C18.7374 2.63767 19.9954 3.61306 21.0088 4.82428C21.9246 5.91893 22.6212 7.18301 23.0612 8.5454C23.1388 8.78548 23.3907 8.92712 23.6332 8.8574V8.8574Z"
			fill={color}
		/>
	</svg>
)
