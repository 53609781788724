'use client'

import cx from 'classnames'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'
import Link from 'components/basic/LinkWithLocale/LinkWithLocale'
import { amClickedButton } from 'events/amplitude'
import { CSSProperties, FC, MouseEvent, useRef } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { formatLink } from 'utils'
import { A } from '../../basic/A'
import styles from './Button.module.scss'
import { ButtonLoadingSpinner } from './ButtonLoadingSpinner'
import { ButtonComponent, ButtonInteriorProps } from './types'

const ButtonContent: FC<ButtonInteriorProps> = (props) => {
	const { loading = false, empty = false, spinnerColor = 'white' } = props
	return (
		<>
			<span
				style={{ opacity: loading ? 0 : 1 }}
				className={empty ? '' : styles.button_span}
			>
				{props.children}
			</span>
			{loading && <ButtonLoadingSpinner color={spinnerColor} />}
		</>
	)
}

const hrefToString = (href: ButtonInteriorProps['href']): string => {
	if (href === undefined) return ''
	if (typeof href === 'string') return href
	if (href.pathname !== null) return href.pathname
	return ''
}

const ButtonBase: FC<ButtonInteriorProps> = (props) => {
	const ref = useRef(null)
	const href = typeof props.href === 'string' ? formatLink(props.href) : props.href

	const { animateOnScroll = false, disabled = false, loading = false, disableSmoothScroll = false, aElement = false } = props

	useAnimateInOnScroll(ref, { ...props.animationSettings, disable: !animateOnScroll })

	const handleClick = (e: MouseEvent<HTMLElement>): void => {
		if (href !== undefined && href !== '') {
			const linkID = props.id || 'unknown_id'
			amClickedButton(hrefToString(href), linkID, props.trackingData)
		} else {
			amClickedButton(props.title ?? '', props.id, props.trackingData)
		}

		if (props.onClick) {
			if (href === '#') e.preventDefault()
			props.onClick(e)
		}
	}

	const commonProps: Record<string, string | boolean | undefined | CSSProperties> & { id: string } = {
		id: props.id,
		className: cx(styles.base, props.injectedClass ?? '', props.className ?? ''),
		role: props.role ?? (href !== undefined ? 'link' : 'button'),
		'aria-label': props.ariaLabel,
		'aria-haspopup': props.ariaHasPopup,
		'aria-expanded': props.ariaExpanded,
		'aria-controls': props.ariaControls,
		style: props.style,
	}

	for (const dataAttribute of props.dataAttributes ?? []) {
		const key = Object.keys(dataAttribute)[0]
		const value = Object.values(dataAttribute)[0]
		commonProps['data-' + key] = value
	}

	const commonLinkProps = {
		...commonProps,
		target: props.target,
		rel: props.target === '_blank' ? 'noopener' : undefined,
		onClick: handleClick,
	}

	if (href === undefined || href === '') {
		return (
			<button
				ref={ref}
				{...commonProps}
				onClick={handleClick}
				disabled={disabled || loading}
				title={props.title}
				type={props.type ?? 'button'}
			>
				<ButtonContent {...props} />
			</button>
		)
	}

	if (hrefToString(href).includes('#') && !disableSmoothScroll) {
		return (
			<AnchorLink
				ref={ref}
				{...commonLinkProps}
				href={hrefToString(href)}
			>
				<ButtonContent {...props} />
			</AnchorLink>
		)
	}

	if (aElement) {
		return (
			<A
				{...commonLinkProps}
				href={href}
				onClick={(e) => handleClick(e as MouseEvent<HTMLElement>)}
			>
				<ButtonContent {...props} />
			</A>
		)
	}

	return (
		<Link
			href={href}
			// @ts-expect-error - ref issue
			ref={ref}
			{...commonLinkProps}
		>
			<ButtonContent {...props} />
		</Link>
	)
}

ButtonBase.displayName = 'Button'

export const Button = Object.assign(ButtonBase, {}) as unknown as ButtonComponent
