import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M15.536 8.46401C16.0004 8.92832 16.3688 9.47957 16.6202 10.0863C16.8716 10.693 17.0009 11.3433 17.0009 12C17.0009 12.6567 16.8716 13.307 16.6202 13.9137C16.3688 14.5204 16.0004 15.0717 15.536 15.536M18.364 5.63601C19.1997 6.47174 19.8627 7.46389 20.315 8.55583C20.7673 9.64777 21.0001 10.8181 21.0001 12C21.0001 13.1819 20.7673 14.3522 20.315 15.4442C19.8627 16.5361 19.1997 17.5283 18.364 18.364M5.586 15H4C3.73478 15 3.48043 14.8946 3.29289 14.7071C3.10536 14.5196 3 14.2652 3 14V10C3 9.73479 3.10536 9.48044 3.29289 9.2929C3.48043 9.10536 3.73478 9.00001 4 9.00001H5.586L10.293 4.29301C10.923 3.66301 12 4.10901 12 5.00001V19C12 19.891 10.923 20.337 10.293 19.707L5.586 15Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
