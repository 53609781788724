import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M2.25 3H8.25C9.31087 3 10.3283 3.42143 11.0784 4.17157C11.8286 4.92172 12.25 5.93913 12.25 7V21C12.25 20.2044 11.9339 19.4413 11.3713 18.8787C10.8087 18.3161 10.0456 18 9.25 18H2.25V3Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22.25 3H16.25C15.1891 3 14.1717 3.42143 13.4216 4.17157C12.6714 4.92172 12.25 5.93913 12.25 7V21C12.25 20.2044 12.5661 19.4413 13.1287 18.8787C13.6913 18.3161 14.4544 18 15.25 18H22.25V3Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
