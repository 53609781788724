import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.68186 3.36365H16.3182C17.2722 3.36365 18.0455 4.13697 18.0455 5.09092V18.9091C18.0455 19.863 17.2722 20.6364 16.3182 20.6364H7.68186C6.72792 20.6364 5.95459 19.863 5.95459 18.9091V5.09092C5.95459 4.13697 6.72792 3.36365 7.68186 3.36365ZM12.0001 16.4318C11.5858 16.4318 11.2501 16.7676 11.2501 17.1818C11.2501 17.596 11.5858 17.9318 12.0001 17.9318H12.0087C12.4229 17.9318 12.7587 17.596 12.7587 17.1818C12.7587 16.7676 12.4229 16.4318 12.0087 16.4318H12.0001Z"
			fill={color}
		/>
	</svg>
)

export default icon
