import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M17.657 16.298L13.414 20.4295C13.2284 20.6104 13.0081 20.7538 12.7656 20.8517C12.523 20.9496 12.263 21 12.0005 21C11.738 21 11.478 20.9496 11.2354 20.8517C10.9929 20.7538 10.7726 20.6104 10.587 20.4295L6.343 16.298C5.22422 15.2085 4.46234 13.8205 4.15369 12.3094C3.84504 10.7983 4.00349 9.23209 4.60901 7.80871C5.21452 6.38532 6.2399 5.16874 7.55548 4.3128C8.87107 3.45686 10.4178 3 12 3C13.5822 3 15.1289 3.45686 16.4445 4.3128C17.7601 5.16874 18.7855 6.38532 19.391 7.80871C19.9965 9.23209 20.155 10.7983 19.8463 12.3094C19.5377 13.8205 18.7758 15.2085 17.657 16.298V16.298Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.4245 13.2615C15.0675 12.6286 15.4287 11.7701 15.4287 10.875C15.4287 9.97989 15.0675 9.12145 14.4245 8.48851C13.7815 7.85558 12.9094 7.5 12.0001 7.5C11.0908 7.5 10.2187 7.85558 9.57574 8.48851C8.93276 9.12145 8.57153 9.97989 8.57153 10.875C8.57153 11.7701 8.93276 12.6286 9.57574 13.2615C10.2187 13.8944 11.0908 14.25 12.0001 14.25C12.9094 14.25 13.7815 13.8944 14.4245 13.2615Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
