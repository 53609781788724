import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.4672 12.6788C16.8189 11.8296 17 10.9193 17 10.0001C17 8.14355 16.2625 6.36307 14.9497 5.05031C13.637 3.73756 11.8565 3.00006 10 3.00006C8.14348 3.00006 6.36301 3.73756 5.05025 5.05031C3.7375 6.36307 3 8.14355 3 10.0001C3 10.9193 3.18106 11.8296 3.53284 12.6788C3.88463 13.5281 4.40024 14.2998 5.05025 14.9498C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.819 9.08075 17.0001 10 17.0001C10.9193 17.0001 11.8295 16.819 12.6788 16.4672C13.2987 16.2105 13.8772 15.8664 14.3973 15.4465C14.419 15.4758 14.4431 15.5038 14.4697 15.5304L20.4697 21.5304C20.7626 21.8233 21.2374 21.8233 21.5303 21.5304C21.8232 21.2375 21.8232 20.7626 21.5303 20.4697L15.5303 14.4697C15.5038 14.4432 15.4757 14.419 15.4465 14.3973C15.8664 13.8773 16.2104 13.2987 16.4672 12.6788ZM7.19398 6.44329C7.50144 6.72085 7.52568 7.19511 7.24812 7.50257C6.9309 7.85396 6.68387 8.26164 6.52083 8.70173C6.3578 9.14177 6.28224 9.6047 6.29702 10.0637C6.3107 10.4885 6.46506 11.0181 6.66919 11.4629C6.84195 11.8394 6.67682 12.2846 6.30035 12.4574C5.92389 12.6301 5.47865 12.465 5.30589 12.0885C5.05111 11.5333 4.82002 10.8019 4.7978 10.112C4.77668 9.45636 4.88471 8.8002 5.11425 8.18061C5.34379 7.56107 5.69064 6.98933 6.1347 6.49743C6.41227 6.18997 6.88652 6.16573 7.19398 6.44329Z"
			fill={color}
		/>
	</svg>
)

export default icon
