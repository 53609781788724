import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.1032 7.23275C6.30604 6.7865 6.75098 6.5 7.24116 6.5H16.945C17.4351 6.5 17.8801 6.7865 18.0829 7.23275L18.7899 8.78816C18.7152 8.77722 18.6392 8.76849 18.5621 8.76206L18.2951 8.73981C18.2744 8.73808 18.2536 8.73722 18.2328 8.73722H7.02111C6.40826 8.73722 5.82105 8.84701 5.27808 9.04801L6.1032 7.23275ZM2.54869 11.4737C2.55979 11.4192 2.57714 11.3652 2.60111 11.3124L4.73765 6.61204C5.1839 5.63031 6.16277 5 7.24116 5H16.945C18.0234 5 19.0022 5.63031 19.4485 6.61204L21.0875 10.2179C21.3143 10.5502 21.4818 10.9203 21.5831 11.3082L21.585 11.3124C21.6329 11.4177 21.6544 11.5283 21.6523 11.6367C21.8198 12.6772 21.5179 13.8011 20.6235 14.6432C21.1934 15.0701 21.527 15.6949 21.5829 16.3655C21.6314 16.9473 21.4637 17.5516 21.0618 18.0183C20.6497 18.4969 20.0353 18.7794 19.3006 18.7794H7.02111C4.24803 18.7794 2 16.5314 2 13.7583C2 12.9355 2.1979 12.159 2.54869 11.4737ZM19.7873 10.9824L20.1561 11.7938C20.2799 12.4104 20.1085 13.09 19.5584 13.5851C19.2071 13.9012 18.7513 14.0761 18.2787 14.0761H7.555C7.3795 14.0761 7.23722 13.9338 7.23722 13.7583C7.23722 13.5828 7.3795 13.4406 7.555 13.4406H18.2328C18.647 13.4406 18.9828 13.1048 18.9828 12.6906C18.9828 12.2763 18.647 11.9406 18.2328 11.9406H7.555C6.55107 11.9406 5.73722 12.7544 5.73722 13.7583C5.73722 14.7623 6.55107 15.5761 7.555 15.5761H18.2787C18.3901 15.5761 18.5009 15.5707 18.6109 15.5599C18.6612 15.5705 18.7133 15.5761 18.7667 15.5761C19.7434 15.5761 20.0563 16.1091 20.0881 16.4901C20.1063 16.7091 20.0405 16.9056 19.9251 17.0395C19.82 17.1616 19.6336 17.2794 19.3006 17.2794H7.02111C5.07646 17.2794 3.5 15.703 3.5 13.7583C3.5 11.8137 5.07646 10.2372 7.02111 10.2372H18.2016L18.4376 10.2569C19.0192 10.3054 19.4827 10.5876 19.7873 10.9824Z"
			fill={color}
		/>
	</svg>
)

export default icon
