import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_6401)">
			<path
				d="M16.318 3.36365H7.68162C6.72767 3.36365 5.95435 4.13697 5.95435 5.09092V18.9091C5.95435 19.863 6.72767 20.6364 7.68162 20.6364H16.318C17.2719 20.6364 18.0453 19.863 18.0453 18.9091V5.09092C18.0453 4.13697 17.2719 3.36365 16.318 3.36365Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M12 17.1818H12.0086"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_6401">
				<rect
					width="13.8182"
					height="19"
					fill={color}
					transform="translate(5.09082 2.5)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
