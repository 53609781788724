import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12.0001 9C11.2061 9.00524 10.4461 9.32299 9.88459 9.88447C9.32312 10.4459 9.00536 11.206 9.00012 12C9.00012 13.642 10.3581 15 12.0001 15C13.6411 15 15.0001 13.642 15.0001 12C15.0001 10.359 13.6411 9 12.0001 9Z"
			fill={color}
		/>
		<path
			d="M12.0001 5C4.36711 5 2.07311 11.617 2.05211 11.684L1.94611 12L2.05111 12.316C2.07311 12.383 4.36711 19 12.0001 19C19.6331 19 21.9271 12.383 21.9481 12.316L22.0541 12L21.9491 11.684C21.9271 11.617 19.6331 5 12.0001 5ZM12.0001 17C6.64911 17 4.57611 13.154 4.07411 12C4.57811 10.842 6.65211 7 12.0001 7C17.3511 7 19.4241 10.846 19.9261 12C19.4221 13.158 17.3481 17 12.0001 17Z"
			fill={color}
		/>
	</svg>
)

export default icon
