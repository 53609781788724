import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M4.00012 8.01008L5.33346 6.72221C6.0772 6.00382 7.25638 6.00382 8.00012 6.72221V6.72221C8.74386 7.44059 9.92305 7.44059 10.6668 6.72221V6.72221C11.4105 6.00382 12.5897 6.00382 13.3335 6.72221V6.72221C14.0772 7.44059 15.2564 7.44059 16.0001 6.72221V6.72221C16.7439 6.00382 17.923 6.00382 18.6668 6.72221L20.0001 8.01008"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.00012 4.57576L5.33346 3.28788C6.0772 2.56949 7.25638 2.56949 8.00012 3.28788V3.28788C8.74386 4.00627 9.92305 4.00627 10.6668 3.28788V3.28788C11.4105 2.56949 12.5897 2.56949 13.3335 3.28788V3.28788C14.0772 4.00627 15.2564 4.00627 16.0001 3.28788V3.28788C16.7439 2.56949 17.923 2.56949 18.6668 3.28788L20.0001 4.57576"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.00012 17.8611V20.6667C3.00012 21.403 3.59708 22 4.33346 22H19.6668C20.4032 22 21.0001 21.403 21.0001 20.6667V17.8611H3.00012ZM20.4853 16.3611L17.8814 11.6847C17.646 11.262 17.2002 11 16.7164 11H7.73205C7.27618 11 6.85191 11.2329 6.60717 11.6175L3.58852 16.3611H20.4853Z"
			fill={color}
		/>
	</svg>
)

export default icon
