import { IconComponentType } from '../../Icon.types'

export const CalendarCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M14.498 18C13.798 18 13.2064 17.7583 12.723 17.275C12.2397 16.7917 11.998 16.2 11.998 15.5C11.998 14.8 12.2397 14.2083 12.723 13.725C13.2064 13.2417 13.798 13 14.498 13C15.198 13 15.7897 13.2417 16.273 13.725C16.7564 14.2083 16.998 14.8 16.998 15.5C16.998 16.2 16.7564 16.7917 16.273 17.275C15.7897 17.7583 15.198 18 14.498 18ZM4.99805 22C4.44805 22 3.97721 21.8042 3.58555 21.4125C3.19388 21.0208 2.99805 20.55 2.99805 20V6C2.99805 5.45 3.19388 4.97917 3.58555 4.5875C3.97721 4.19583 4.44805 4 4.99805 4H5.99805V2H7.99805V4H15.998V2H17.998V4H18.998C19.548 4 20.0189 4.19583 20.4105 4.5875C20.8022 4.97917 20.998 5.45 20.998 6V20C20.998 20.55 20.8022 21.0208 20.4105 21.4125C20.0189 21.8042 19.548 22 18.998 22H4.99805ZM4.99805 20H18.998V10H4.99805V20ZM4.99805 8H18.998V6H4.99805V8Z"
			fill={color}
		/>
	</svg>
)
