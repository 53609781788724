import { IconComponentType } from '../../Icon.types'

export const BookCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 25 25'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M2.51929 3.29401H8.51929C9.58015 3.29401 10.5976 3.71543 11.3477 4.46558C12.0979 5.21572 12.5193 6.23314 12.5193 7.29401V21.294C12.5193 20.4984 12.2032 19.7353 11.6406 19.1727C11.078 18.6101 10.3149 18.294 9.51929 18.294H2.51929V3.29401Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22.5195 3.29401H16.5195C15.4587 3.29401 14.4412 3.71543 13.6911 4.46558C12.941 5.21572 12.5195 6.23314 12.5195 7.29401V21.294C12.5195 20.4984 12.8356 19.7353 13.3982 19.1727C13.9608 18.6101 14.7239 18.294 15.5195 18.294H22.5195V3.29401Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
