import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12 20C16.97 20 21 16.418 21 12C21 7.582 16.97 4 12 4C7.03 4 3 7.582 3 12C3 13.574 3.512 15.042 4.395 16.28L3 20L7.745 19.051C9.07479 19.6808 10.5286 20.005 12 20Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 12H8.01M12 12H12.01M16 12H16.01"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
