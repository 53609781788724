import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_4874)">
			<path
				d="M20.8177 16.9718C20.8177 16.9718 20.8176 16.9718 20.8176 16.9719L20.1105 16.7311M20.8177 16.9718L20.1105 16.7311M20.8177 16.9718C20.8196 16.9661 20.8215 16.9604 20.8234 16.9547C20.8635 16.8327 20.8896 16.7072 20.8994 16.5798L20.8177 16.9718ZM20.1105 16.7311C20.0922 16.7833 20.0707 16.8327 20.0464 16.8791C20.0693 16.8326 20.0897 16.7829 20.1077 16.7301C20.1293 16.6665 20.1434 16.604 20.1498 16.5427C20.1458 16.6023 20.1344 16.6606 20.1157 16.7159L20.1156 16.7163C20.1139 16.7213 20.1123 16.7262 20.1105 16.7311ZM19.2495 17.3763C19.2296 17.3767 19.2097 17.377 19.1898 17.3773C19.1963 17.3773 19.2028 17.3773 19.2093 17.3772L19.2495 17.3763ZM19.2495 17.3763C19.2362 17.3769 19.2229 17.3772 19.2094 17.3772L19.2495 17.3763ZM4.9879 10.7048C5.24666 10.5434 5.5268 10.4089 5.82518 10.3061L4.9879 10.7048ZM4.9879 10.7048C5.12161 10.3825 5.25547 10.0602 5.38937 9.73808C5.64289 9.12933 5.89459 8.52246 6.14624 7.9139L4.9879 10.7048ZM19.3494 10.3956C19.0271 9.53503 18.71 8.70428 18.419 7.94391L18.4189 7.9436C18.2408 7.47764 18.0281 7.21257 17.7977 7.05472C17.5667 6.89651 17.2393 6.79222 16.7333 6.79222H16.7324C15.2991 6.79042 13.864 6.79042 12.4301 6.79042C11.6659 6.79042 10.9016 6.79087 10.1371 6.79132L10.137 6.79132C9.37248 6.79177 8.60773 6.79222 7.84295 6.79222C7.38525 6.79222 7.04779 6.89714 6.79401 7.06558C6.54076 7.23366 6.31742 7.50043 6.14633 7.91368L19.3494 10.3956ZM19.3494 10.3956C18.9777 10.2131 18.5493 10.1064 18.0755 10.1063L18.0706 10.1064C16.9765 10.1136 15.8372 10.1172 14.4712 10.1172C13.3832 10.1172 12.2952 10.1154 11.2072 10.1118L11.2059 10.1118C10.844 10.1112 10.4816 10.1104 10.1189 10.1096C9.39241 10.108 8.66495 10.1064 7.93818 10.1064H7.374H6.75862L6.75382 10.1305C6.45179 10.1506 6.14029 10.1974 5.82529 10.3061L19.3494 10.3956Z"
				fill={color}
				stroke={color}
				strokeWidth="1.5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_4874">
				<rect
					width="17.8217"
					height="12.2653"
					fill={color}
					transform="translate(3.08911 5.86734)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
