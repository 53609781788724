import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M11.5552 9.03595L14.7522 11.1679C14.8892 11.2593 15.0015 11.384 15.0792 11.5291C15.1568 11.6743 15.1975 11.8363 15.1975 12.0009C15.1975 12.1656 15.1568 12.3276 15.0792 12.4728C15.0015 12.6179 14.8892 12.7416 14.7522 12.8329L11.5552 14.9649C11.4046 15.0654 11.2296 15.1231 11.0488 15.1319C10.8679 15.1407 10.6881 15.1002 10.5285 15.0148C10.3689 14.9294 10.2354 14.8023 10.1424 14.647C10.0493 14.4916 10.0002 14.314 10.0002 14.1329V9.86995C9.99986 9.68874 10.0487 9.51082 10.1416 9.35523C10.2345 9.19963 10.3679 9.07219 10.5276 8.98655C10.6873 8.9009 10.8673 8.86025 11.0483 8.86895C11.2293 8.87765 11.4045 8.93538 11.5552 9.03595Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.3149 15.4441C20.7672 14.3522 21 13.1818 21 11.9999C21 9.61299 20.0518 7.32381 18.364 5.63598C16.6761 3.94815 14.3869 2.99994 12 2.99994C9.61305 2.99994 7.32387 3.94815 5.63604 5.63598C3.94821 7.32381 3 9.61299 3 11.9999C3 13.1818 3.23279 14.3522 3.68508 15.4441C4.13738 16.536 4.80031 17.5282 5.63604 18.3639C6.47177 19.1996 7.46392 19.8626 8.55585 20.3149C9.64778 20.7671 10.8181 20.9999 12 20.9999C13.1819 20.9999 14.3522 20.7671 15.4442 20.3149C16.5361 19.8626 17.5282 19.1996 18.364 18.3639C19.1997 17.5282 19.8626 16.536 20.3149 15.4441Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
