import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12.7639 15.2143V5.92857C12.7639 5.6823 12.6586 5.44611 12.471 5.27197C12.2835 5.09783 12.0291 5 11.7639 5H3.76392C3.4987 5 3.24435 5.09783 3.05681 5.27197C2.86927 5.44611 2.76392 5.6823 2.76392 5.92857V15.2143C2.76392 15.4606 2.86927 15.6967 3.05681 15.8709C3.24435 16.045 3.4987 16.1429 3.76392 16.1429H4.26392M12.7639 15.2143C12.7639 15.4606 12.6586 15.6967 12.471 15.8709C12.2835 16.045 12.0291 16.1429 11.7639 16.1429H9.26392M12.7639 15.2143V7.78571C12.7639 7.53944 12.8693 7.30326 13.0568 7.12912C13.2443 6.95497 13.4987 6.85714 13.7639 6.85714H16.3499C16.6151 6.8572 16.8694 6.95506 17.0569 7.12921L20.4709 10.2994C20.6585 10.4735 20.7639 10.7096 20.7639 10.9559V15.2143C20.7639 15.4606 20.6586 15.6967 20.471 15.8709C20.2835 16.045 20.0291 16.1429 19.7639 16.1429H19.2639M12.7639 15.2143C12.7639 15.4606 12.8693 15.6967 13.0568 15.8709C13.2443 16.045 13.4987 16.1429 13.7639 16.1429H14.2639M4.76392 16.1429C4.76392 16.6355 4.97463 17.1078 5.3497 17.4561C5.72478 17.8044 6.23348 18.0001 6.76392 18.0001C7.29435 18.0001 7.80306 17.8044 8.17813 17.4561C8.5532 17.1078 8.76392 16.6355 8.76392 16.1429C8.76392 15.6504 8.5532 15.178 8.17813 14.8297C7.80306 14.4814 7.29435 14.2858 6.76392 14.2858C6.23348 14.2858 5.72478 14.4814 5.3497 14.8297C4.97463 15.178 4.76392 15.6504 4.76392 16.1429ZM14.7639 16.1429C14.7639 16.6355 14.9746 17.1078 15.3497 17.4561C15.7248 17.8044 16.2335 18.0001 16.7639 18.0001C17.2943 18.0001 17.8031 17.8044 18.1781 17.4561C18.5532 17.1078 18.7639 16.6355 18.7639 16.1429C18.7639 15.6504 18.5532 15.178 18.1781 14.8297C17.8031 14.4814 17.2943 14.2858 16.7639 14.2858C16.2335 14.2858 15.7248 14.4814 15.3497 14.8297C14.9746 15.178 14.7639 15.6504 14.7639 16.1429Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
