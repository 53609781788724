import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<circle
			cx="12.2481"
			cy="6.59336"
			r="3.91196"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.21826 13.1013C5.17917 11.6599 7.88406 11.3927 10.2981 12.8955C11.4285 13.5991 13.0531 13.6005 14.1336 12.8224C15.8249 11.6044 18.2321 10.7827 20.2779 13.1013"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.25684 18.9427L8.50684 21.4219M8.50684 21.4219L10.7568 18.9427M8.50684 21.4219L8.50684 15.0469"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.1318 18.9427L16.3818 21.4219M16.3818 21.4219L18.6318 18.9427M16.3818 21.4219L16.3818 15.0469"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
