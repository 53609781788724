import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.618 6.09537C17.4561 6.26635 14.3567 5.15313 12 3C9.64327 5.15313 6.5439 6.26635 3.382 6.09537C3.12754 7.09843 2.99918 8.13033 3 9.16631C3 14.8591 6.824 19.6437 12 21C17.176 19.6437 21 14.8602 21 9.16631C21 8.10533 20.867 7.07693 20.618 6.09537ZM15.5351 10.71C15.8253 10.4145 15.821 9.93965 15.5255 9.64941C15.23 9.35918 14.7551 9.36346 14.4649 9.65899L11 13.187L9.53509 11.6954C9.24486 11.3999 8.77 11.3956 8.47448 11.6858C8.17895 11.9761 8.17467 12.4509 8.46491 12.7465L10.4649 14.7829C10.6059 14.9265 10.7987 15.0074 11 15.0074C11.2013 15.0074 11.3941 14.9265 11.5351 14.7829L15.5351 10.71Z"
			fill={color}
		/>
	</svg>
)

export default icon
