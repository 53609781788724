import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_6389)">
			<path
				d="M11.8091 12V20.1"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.3601 17.751C21.5381 16.563 22.3481 13.827 21.1601 11.649C20.3681 10.2 18.8561 9.3 17.2091 9.3H16.0751C15.0761 5.448 11.1521 3.135 7.30009 4.134C3.44809 5.133 1.13509 9.057 2.13409 12.9C2.42209 13.998 2.96209 15.015 3.70909 15.87"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.409 15.6L11.809 12L8.20898 15.6"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_6389">
				<rect
					width="21.6"
					height="18"
					fill={color}
					transform="translate(1 3)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
