import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M17.9513 13.9037H7.84767L6.32178 6.60556H20.7638L17.9513 13.9037Z"
			fill={color}
		/>
		<path
			d="M2.76379 5H5.81558L6.32178 6.60556M6.32178 6.60556L7.84767 13.9037H17.9513L20.7638 6.60556H6.32178Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="16.6353"
			cy="17.9369"
			rx="1.12832"
			ry="1.0631"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
		/>
		<ellipse
			cx="9.3013"
			cy="17.935"
			rx="1.12832"
			ry="1.0631"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
		/>
	</svg>
)

export default icon
