import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M10.8 17.6L8.675 12.925L4 10.8L8.675 8.675L10.8 4L12.925 8.675L17.6 10.8L12.925 12.925L10.8 17.6ZM17.6 19.3L16.5375 16.9625L14.2 15.9L16.5375 14.8375L17.6 12.5L18.6625 14.8375L21 15.9L18.6625 16.9625L17.6 19.3Z"
			fill={color}
		/>
	</svg>
)

export default icon
