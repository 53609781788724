import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M10.9358 13.8603C10.89 13.8313 10.8523 13.7913 10.8262 13.7439C10.8001 13.6964 10.7863 13.6432 10.7863 13.589V4.92879C10.7863 4.41724 10.5831 3.92665 10.2213 3.56493C9.85963 3.20321 9.36903 3 8.85749 3C8.34594 3 7.85535 3.20321 7.49363 3.56493C7.13191 3.92665 6.9287 4.41724 6.9287 4.92879V13.589C6.92861 13.6431 6.91491 13.6962 6.88885 13.7436C6.8628 13.7909 6.82523 13.8309 6.77962 13.8599C6.2083 14.232 5.74399 14.7468 5.43257 15.3534C5.12115 15.96 4.9734 16.6373 5.00393 17.3184C5.04991 18.3251 5.48795 19.2737 6.22436 19.9616C6.96077 20.6494 7.93709 21.0217 8.94451 20.999C9.95192 20.9763 10.9105 20.5603 11.6151 19.8399C12.3197 19.1196 12.7145 18.1521 12.7151 17.1444C12.7153 16.4922 12.5527 15.8502 12.242 15.2766C11.9313 14.7031 11.4823 14.2163 10.9358 13.8603Z"
			stroke={color}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M8.85725 19.0733C9.9225 19.0733 10.786 18.2097 10.786 17.1445C10.786 16.0792 9.9225 15.2157 8.85725 15.2157C7.79201 15.2157 6.92847 16.0792 6.92847 17.1445C6.92847 18.2097 7.79201 19.0733 8.85725 19.0733Z"
			fill={color}
		/>
		<path
			d="M14 4.125H18.5"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M14 14.25H18.5"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M14 7.5H18.5"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M14 10.875H18.5"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M8.85742 6.21466V17.1445"
			stroke={color}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
	</svg>
)

export default icon
