'use client'

import { FC } from 'react'
import styles from './Img.module.scss'
import cx from 'classnames'
import { Url } from 'url'
import { NextLinkHref } from 'constants/types'
import { InteractiveImg } from './InteractiveImg'
import { getSrcSet, srcToImageCDN } from './Img.utils'
import { ImageConfig } from './Img.types'

export interface Source {
	src: string
	mediaQuery: string
	dprHeight?: number
	type?: 'mobile' | 'desktop'
}

export interface ImgProps {
	src: string
	alt: string

	/** An optional array of image sources (used mobile vs. desktop images, etc.) */
	sources?: Source[]

	/** Optional parameter to eager vs lazy-load the image. Lazy load is default.
	 * This should be changed to eager? at the earliest opportunity. */
	lazy?: boolean

	className?: string
	id?: string
	onClick?: () => void

	/** The estimated max-height of this image.
	 * This is used for DPR calculations, and while it is optional, it is highly recommended for proper image handling.
	 * This will default to 1000 if left unset. */
	dprHeight?: number

	/**
	 * Discuss whether this is necessary
	 */
	simple?: boolean

	href?: string | Url | NextLinkHref

	objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset'

	/**
	 * Rarely used height prop kept for backwards compatibility
	 */
	height?: number

	unoptimized?: boolean

	doNotCompress?: boolean

	config?: ImageConfig
}

const DEFAULT_DPR_HEIGHT = 1000

/**
 * Simple responsive image component. Handles Imgix conversion and handles multiple DPRs.
 */
export const Img: FC<ImgProps> = (props) => {
	if (props.simple) {
		return renderSimpleImg(props)
	}

	if (props.href || props.onClick) {
		return (
			<InteractiveImg
				imgProps={props}
				sources={generateSources(props)}
				simpleImage={renderSimpleImg(props)}
			/>
		)
	}

	return (
		<picture className={props.className}>
			{generateSources(props)}
			{renderSimpleImg(props)}
		</picture>
	)
}

const generateSources = ({ sources, unoptimized, doNotCompress, src, config }: ImgProps) => {
	if (!sources || !sources.length) return null

	return sources.map((source) => {
		const dims = {
			width: config?.desktop?.width,
			height: config?.desktop?.height || DEFAULT_DPR_HEIGHT,
		}

		if (source.type === 'mobile') {
			dims.width = config?.mobile?.width
			dims.height = config?.mobile?.height || DEFAULT_DPR_HEIGHT
		}

		return (
			<source
				key={source.mediaQuery + src}
				media={source.mediaQuery}
				srcSet={unoptimized ? source.src : getSrcSet(source.src, dims, doNotCompress)}
			/>
		)
	})
}

const renderSimpleImg = ({ lazy, className, simple, src, doNotCompress, alt, id, unoptimized, dprHeight, objectFit, config }: ImgProps) => {
	const loadingType = lazy === undefined || lazy ? 'lazy' : 'eager'
	return (
		// eslint-disable-next-line @next/next/no-img-element
		<img
			className={cx(styles.img, { [className as string]: simple })}
			src={srcToImageCDN(src, doNotCompress, {
				width: config?.desktop?.width,
				height: config?.desktop?.height || dprHeight || DEFAULT_DPR_HEIGHT,
			})}
			alt={alt}
			loading={loadingType}
			id={id}
			srcSet={
				unoptimized
					? src
					: getSrcSet(
							src,
							{
								width: config?.desktop?.width,
								height: config?.desktop?.height || dprHeight || DEFAULT_DPR_HEIGHT,
							},
							doNotCompress
					  )
			}
			style={{ objectFit: objectFit || 'contain' }}
		/>
	)
}
