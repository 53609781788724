import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M9 20H19V18C19 17.344 18.874 16.717 18.644 16.143C18.2726 15.215 17.6318 14.4195 16.804 13.8591C15.9762 13.2988 14.9996 12.9993 14 12.9993C13.0004 12.9993 12.0238 13.2988 11.196 13.8591C10.3682 14.4195 9.72736 15.215 9.356 16.143M9 20H4V18C4.00005 17.3765 4.19434 16.7686 4.55586 16.2606C4.91739 15.7527 5.42819 15.37 6.01725 15.1658C6.60632 14.9615 7.24438 14.9459 7.84274 15.121C8.4411 15.2962 8.97003 15.6534 9.356 16.143M9 20V18C9 17.344 9.126 16.717 9.356 16.143M17 7C17 7.79565 16.6839 8.55871 16.1213 9.12132C15.5587 9.68393 14.7956 10 14 10C13.2044 10 12.4413 9.68393 11.8787 9.12132C11.3161 8.55871 11 7.79565 11 7C11 6.20435 11.3161 5.44129 11.8787 4.87868C12.4413 4.31607 13.2044 4 14 4C14.7956 4 15.5587 4.31607 16.1213 4.87868C16.6839 5.44129 17 6.20435 17 7ZM9 10C9 10.5304 8.78929 11.0391 8.41421 11.4142C8.03914 11.7893 7.53043 12 7 12C6.46957 12 5.96086 11.7893 5.58579 11.4142C5.21071 11.0391 5 10.5304 5 10C5 9.46957 5.21071 8.96086 5.58579 8.58579C5.96086 8.21071 6.46957 8 7 8C7.53043 8 8.03914 8.21071 8.41421 8.58579C8.78929 8.96086 9 9.46957 9 10Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
