import { IconComponentType } from '../../Icon.types'

export const WeatherCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.6362 5.63397C14.1171 2.63992 9.84863 2.61718 8.29775 5.59488L7.99142 6.18303C7.88814 6.38133 7.70176 6.52321 7.48312 6.56999L6.727 6.73173L6.49565 6.78628C3.28531 7.54317 2.69357 11.8572 5.58154 13.4506C6.11475 13.7448 6.7138 13.8991 7.32278 13.8991H17.773C18.2799 13.8991 18.7766 13.7566 19.2064 13.488C21.4745 12.0704 20.5283 8.57221 17.8549 8.4912L16.866 8.46123C16.4937 8.44995 16.186 8.16708 16.1436 7.79697L16.0558 7.03093C16 6.54405 15.8579 6.07101 15.6362 5.63397ZM6.96738 4.90198C9.08212 0.841676 14.9025 0.872683 16.9739 4.95529C17.2762 5.55121 17.47 6.19624 17.5461 6.86014L17.56 6.98158L17.9003 6.99189C22.0565 7.11783 23.5274 12.5562 20.0014 14.76C19.3332 15.1776 18.561 15.3991 17.773 15.3991H7.32278C6.46038 15.3991 5.61203 15.1806 4.85692 14.764C0.767136 12.5075 1.60512 6.39817 6.15143 5.32631L6.39037 5.26997L6.40559 5.26655L6.82412 5.17702L6.96738 4.90198ZM17.1922 17.2475C17.4986 17.5262 17.521 18.0005 17.2423 18.3069L16.4016 19.2311C16.1228 19.5375 15.6485 19.56 15.3421 19.2812C15.0357 19.0025 15.0133 18.5282 15.292 18.2218L16.1327 17.2976C16.4115 16.9912 16.8858 16.9687 17.1922 17.2475ZM14.2423 21.3069C14.521 21.0005 14.4986 20.5262 14.1922 20.2475C13.8858 19.9687 13.4115 19.9912 13.1327 20.2976L12.292 21.2218C12.0133 21.5282 12.0357 22.0025 12.3421 22.2812C12.6485 22.56 13.1228 22.5375 13.4016 22.2311L14.2423 21.3069ZM8.84357 16.5094C9.1413 16.7974 9.14921 17.2722 8.86124 17.5699L7.48419 18.9936C7.19622 19.2914 6.72141 19.2993 6.42368 19.0113C6.12595 18.7233 6.11803 18.2485 6.406 17.9508L7.78305 16.5271C8.07103 16.2293 8.54583 16.2214 8.84357 16.5094ZM13.3687 17.587C13.6661 17.2987 13.6736 16.8239 13.3853 16.5265C13.097 16.2291 12.6222 16.2217 12.3247 16.51L9.17719 19.5608C8.87977 19.8491 8.87236 20.3239 9.16065 20.6213C9.44894 20.9188 9.92376 20.9262 10.2212 20.6379L13.3687 17.587Z"
			fill={color}
		/>
	</svg>
)
