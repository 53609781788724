import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M14.4473 7.74669L12.0986 10.1309M12.0986 10.1309L9.74994 7.74669M12.0986 10.1309L12.0986 4.00004"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 20.0001L4.59975 18.4315C5.3775 17.6689 6.6225 17.6689 7.40025 18.4315L7.59975 18.6271C8.3775 19.3897 9.6225 19.3897 10.4002 18.6271L10.5998 18.4315C11.3775 17.6689 12.6225 17.6689 13.4002 18.4315L13.5998 18.6271C14.3775 19.3897 15.6225 19.3897 16.4002 18.6271L16.5998 18.4315C17.3775 17.6689 18.6225 17.6689 19.4002 18.4315L21 20.0001"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 16.0779L4.59975 14.5093C5.3775 13.7467 6.6225 13.7467 7.40025 14.5093L7.59975 14.7049C8.3775 15.4675 9.6225 15.4675 10.4002 14.7049L10.5998 14.5093C11.3775 13.7467 12.6225 13.7467 13.4002 14.5093L13.5998 14.7049C14.3775 15.4675 15.6225 15.4675 16.4002 14.7049L16.5998 14.5093C17.3775 13.7467 18.6225 13.7467 19.4002 14.5093L21 16.0779"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
