import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M2.76367 5H5.81546L6.32165 6.60556M6.32165 6.60556L7.84754 13.9037H17.9512L20.7637 6.60556H6.32165Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="16.6352"
			cy="17.9369"
			rx="1.12832"
			ry="1.0631"
			stroke={color}
			strokeWidth="1.5"
		/>
		<ellipse
			cx="9.30118"
			cy="17.935"
			rx="1.12832"
			ry="1.0631"
			stroke={color}
			strokeWidth="1.5"
		/>
	</svg>
)

export default icon
