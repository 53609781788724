import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M14.1462 10H18.9735C19.3189 10 19.6585 10.0871 19.9602 10.2531C20.2619 10.4191 20.5156 10.6584 20.6972 10.9484C20.8788 11.2383 20.9824 11.5692 20.9979 11.9098C21.0135 12.2503 20.9407 12.5891 20.7863 12.894L17.2398 19.894C17.0714 20.2265 16.8125 20.5061 16.4921 20.7014C16.1718 20.8968 15.8026 21.0002 15.426 21H11.3556C11.1904 21 11.0252 20.98 10.8641 20.94L7.05316 20M14.1462 10V5C14.1462 4.46957 13.9327 3.96086 13.5526 3.58579C13.1726 3.21071 12.6571 3 12.1196 3H12.0233C11.5167 3 11.1063 3.405 11.1063 3.905C11.1063 4.619 10.8925 5.317 10.4902 5.911L7.05316 11V20M14.1462 10H12.1196M7.05316 20H5.02658C4.4891 20 3.97363 19.7893 3.59357 19.4142C3.21351 19.0391 3 18.5304 3 18V12C3 11.4696 3.21351 10.9609 3.59357 10.5858C3.97363 10.2107 4.4891 10 5.02658 10H7.5598"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
