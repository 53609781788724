import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M7.82028 16.3773L8.10153 19.5109C8.13774 19.9189 8.32292 20.2982 8.62048 20.5739C8.91805 20.8496 9.30635 21.0016 9.70868 21H13.1881C13.5905 21.0016 13.9788 20.8496 14.2763 20.5739C14.5739 20.2982 14.7591 19.9189 14.7953 19.5109L15.0434 16.7464M7.82272 7.62273L8.10397 4.4891C8.14005 4.08253 8.32409 3.70437 8.61995 3.42886C8.91581 3.15336 9.30213 3.0004 9.70307 3.00001H13.1986C13.6009 2.99835 13.9892 3.15039 14.2868 3.4261C14.5844 3.70181 14.7695 4.08112 14.8058 4.4891L15.0433 7.13634"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.625 17.7272C14.7316 17.7272 17.25 15.163 17.25 12C17.25 8.83689 14.7316 6.27271 11.625 6.27271C8.5184 6.27271 6 8.83689 6 12C6 15.163 8.5184 17.7272 11.625 17.7272Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.8223 10.3636V13.0909L12.4294 14.4545"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
