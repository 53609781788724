import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<circle
			cx="7"
			cy="16"
			r="2"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 5C2.44772 5 2 5.44771 2 6V15C2 15.5523 2.44772 16 3 16H4C4 14.3431 5.34315 13 7 13C8.65685 13 10 14.3431 10 16H11C11.5523 16 12 15.5523 12 15V6C12 5.44772 11.5523 5 11 5H3Z"
			fill={color}
		/>
		<circle
			cx="17"
			cy="16"
			r="2"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 7C13.4477 7 13 7.44772 13 8V15C13 15.5523 13.4477 16 14 16C14 14.3431 15.3431 13 17 13C18.6569 13 20 14.3431 20 16C20.5523 16 21 15.5523 21 15V10.5C21 10.1852 20.8518 9.88885 20.6 9.7L17.2667 7.2C17.0936 7.07018 16.883 7 16.6667 7H14Z"
			fill={color}
		/>
	</svg>
)

export default icon
