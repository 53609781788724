'use client'

import React, { FC } from 'react'
import { ButtonProps } from './types'
import { Button } from './Button'
import { Icon } from '../Icon'
import styles from './Button.module.scss'

export const Primary: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.primary}
		spinnerColor="black"
	>
		{props.children}
	</Button>
)

export const White: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.white}
		spinnerColor="black"
	>
		{props.children}
	</Button>
)

export const Blue: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.blue}
		spinnerColor="black"
	>
		{props.children}
	</Button>
)

export const Dark: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.dark}
		spinnerColor="black"
	>
		{props.children}
	</Button>
)

export const TextLink: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.text_link}
		spinnerColor="black"
	>
		{props.children}
		<Icon
			name="ChevronRightLight"
			color="#1862FF"
		/>
	</Button>
)

export const Empty: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.empty}
		spinnerColor="black"
		empty
	>
		{props.children}
	</Button>
)

export const Plus: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.plus}
		spinnerColor="black"
	>
		{props.children}
		<Icon
			name="PlusDark"
			color="black"
		/>
	</Button>
)

export const PlusSmall: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.plus_small}
		spinnerColor="black"
	>
		{props.children}
		<Icon
			name="PlusDark"
			color="black"
			size="16px"
		/>
	</Button>
)

export const VideoGrey: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.plus}
		spinnerColor="black"
	>
		<Icon
			name="PlayDark"
			color="black"
			size="24px"
		/>
		{props.children}
	</Button>
)

export const VideoGreyOutline: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.video_grey_outline}
		spinnerColor="black"
	>
		<Icon
			name="PlayDark"
			color="black"
			size="24px"
		/>
		{props.children}
	</Button>
)

export const InfoGrey: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.info_grey}
		spinnerColor="black"
	>
		<Icon
			name="InformationCircleLight"
			color="black"
			size={16}
		/>
		{props.children}
	</Button>
)

export const TextLinkWhite: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.text_link_white}
		spinnerColor="white"
	>
		{props.children}
		<Icon
			name="ChevronRightLight"
			color="white"
		/>
	</Button>
)

export const Autopilot: FC<ButtonProps> = (props) => (
	<Button
		{...props}
		injectedClass={styles.autopilot}
		spinnerColor="black"
	>
		{props.children}
	</Button>
)
