import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.6362 5.63401C14.1171 2.63996 9.84862 2.61722 8.29774 5.59492L7.99141 6.18307C7.88813 6.38137 7.70176 6.52325 7.48312 6.57002L6.72699 6.73177L6.49564 6.78632C3.2853 7.5432 2.69356 11.8573 5.58153 13.4506C6.11474 13.7448 6.7138 13.8991 7.32278 13.8991H17.773C18.2799 13.8991 18.7765 13.7567 19.2064 13.488C21.4745 12.0704 20.5283 8.57225 17.8549 8.49124L16.866 8.46127C16.4937 8.44999 16.186 8.16712 16.1436 7.79701L16.0558 7.03097C16 6.54409 15.8579 6.07105 15.6362 5.63401ZM6.96737 4.90201C9.08211 0.841716 14.9025 0.872723 16.9739 4.95533C17.2762 5.55125 17.47 6.19628 17.5461 6.86018L17.56 6.98161L17.9003 6.99193C22.0565 7.11787 23.5274 12.5562 20.0014 14.76C19.3331 15.1777 18.561 15.3991 17.773 15.3991H7.32278C6.46037 15.3991 5.61202 15.1806 4.85691 14.764C0.76713 12.5076 1.60512 6.39821 6.15143 5.32635L6.39037 5.27001L6.40558 5.26659L6.82412 5.17706L6.96737 4.90201ZM17.1922 17.2475C17.4986 17.5262 17.521 18.0006 17.2423 18.307L16.4016 19.2312C16.1228 19.5376 15.6485 19.56 15.3421 19.2813C15.0357 19.0025 15.0132 18.5282 15.292 18.2218L16.1327 17.2976C16.4115 16.9912 16.8858 16.9688 17.1922 17.2475ZM14.2423 21.307C14.521 21.0006 14.4986 20.5262 14.1922 20.2475C13.8858 19.9688 13.4115 19.9912 13.1327 20.2976L12.292 21.2218C12.0132 21.5282 12.0357 22.0025 12.3421 22.2813C12.6485 22.56 13.1228 22.5376 13.4016 22.2312L14.2423 21.307ZM8.84356 16.5094C9.14129 16.7974 9.14921 17.2722 8.86124 17.57L7.48419 18.9937C7.19622 19.2914 6.72141 19.2993 6.42368 19.0114C6.12594 18.7234 6.11803 18.2486 6.406 17.9508L7.78305 16.5271C8.07102 16.2294 8.54583 16.2215 8.84356 16.5094ZM13.3687 17.5871C13.6661 17.2988 13.6735 16.824 13.3853 16.5265C13.097 16.2291 12.6222 16.2217 12.3247 16.51L9.17719 19.5608C8.87976 19.8491 8.87236 20.3239 9.16065 20.6214C9.44893 20.9188 9.92375 20.9262 10.2212 20.6379L13.3687 17.5871Z"
			fill={color}
		/>
	</svg>
)

export default icon
