import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 12C21 16.418 16.97 20 12 20C10.5286 20.005 9.07479 19.6808 7.745 19.051L3 20L4.395 16.28C3.512 15.042 3 13.574 3 12C3 7.582 7.03 4 12 4C16.97 4 21 7.582 21 12ZM8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H8.01C8.42421 12.75 8.76 12.4142 8.76 12C8.76 11.5858 8.42421 11.25 8.01 11.25H8ZM12 11.25C11.5858 11.25 11.25 11.5858 11.25 12C11.25 12.4142 11.5858 12.75 12 12.75H12.01C12.4242 12.75 12.76 12.4142 12.76 12C12.76 11.5858 12.4242 11.25 12.01 11.25H12ZM16 11.25C15.5858 11.25 15.25 11.5858 15.25 12C15.25 12.4142 15.5858 12.75 16 12.75H16.01C16.4242 12.75 16.76 12.4142 16.76 12C16.76 11.5858 16.4242 11.25 16.01 11.25H16Z"
			fill={color}
		/>
	</svg>
)

export default icon
