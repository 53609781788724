import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 3C13.1819 3 14.3522 3.23279 15.4442 3.68508C16.5361 4.13738 17.5282 4.80031 18.364 5.63604C19.1997 6.47177 19.8626 7.46392 20.3149 8.55585C20.7672 9.64778 21 10.8181 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3ZM15.5303 10.4697L12.5303 7.46967C12.2374 7.17678 11.7626 7.17678 11.4697 7.46967L8.46967 10.4697C8.17678 10.7626 8.17678 11.2374 8.46967 11.5303C8.76256 11.8232 9.23744 11.8232 9.53033 11.5303L11.25 9.81066V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V9.81066L14.4697 11.5303C14.7626 11.8232 15.2374 11.8232 15.5303 11.5303C15.8232 11.2374 15.8232 10.7626 15.5303 10.4697Z"
			fill={color}
		/>
	</svg>
)

export default icon
