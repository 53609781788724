import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 20V10.5L12 3.5L19 10.5V20C19 20.5523 18.5523 21 18 21H14.75V16C14.75 15.0335 13.9665 14.25 13 14.25H11C10.0335 14.25 9.25 15.0335 9.25 16V21H6C5.44772 21 5 20.5523 5 20Z"
			fill={color}
		/>
	</svg>
)

export default icon
