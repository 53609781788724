import { IconComponentType } from '../../Icon.types'

export const TempCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M11.998 22C10.6147 22 9.43555 21.5125 8.46055 20.5375C7.48555 19.5625 6.99805 18.3833 6.99805 17C6.99805 16.2 7.17305 15.4542 7.52305 14.7625C7.87305 14.0708 8.36471 13.4833 8.99805 13V5C8.99805 4.16667 9.28971 3.45833 9.87305 2.875C10.4564 2.29167 11.1647 2 11.998 2C12.8314 2 13.5397 2.29167 14.123 2.875C14.7064 3.45833 14.998 4.16667 14.998 5V13C15.6314 13.4833 16.123 14.0708 16.473 14.7625C16.823 15.4542 16.998 16.2 16.998 17C16.998 18.3833 16.5105 19.5625 15.5355 20.5375C14.5605 21.5125 13.3814 22 11.998 22ZM10.998 11H12.998V10H11.998V9H12.998V7H11.998V6H12.998V5C12.998 4.71667 12.9022 4.47917 12.7105 4.2875C12.5189 4.09583 12.2814 4 11.998 4C11.7147 4 11.4772 4.09583 11.2855 4.2875C11.0939 4.47917 10.998 4.71667 10.998 5V11Z"
			fill={color}
		/>
	</svg>
)
