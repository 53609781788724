import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M3.07635 11.7944L6.17424 6.92625C6.54136 6.34935 7.17776 6 7.86156 6H16.0882C16.8138 6 17.4825 6.39304 17.8355 7.02703L20.511 11.832C20.6767 12.1296 20.7637 12.4645 20.7637 12.805V15C20.7637 16.1046 19.8682 17 18.7637 17H4.76367C3.6591 17 2.76367 16.1046 2.76367 15V12.8681C2.76367 12.4877 2.87214 12.1153 3.07635 11.7944Z"
			stroke={color}
			strokeWidth="1.5"
		/>
		<path
			d="M20.7637 12.1111H2.76367"
			stroke={color}
			strokeWidth="1.5"
		/>
	</svg>
)

export default icon
