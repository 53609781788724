import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M16.7505 19.2504V13.7504H19.2505V19.2504H16.7505ZM10.7505 19.2504V4.7504H13.2505V19.2504H10.7505ZM4.75049 19.2504V9.7504H7.25049V19.2504H4.75049Z"
			fill="black"
			stroke={color}
			strokeWidth="1.5"
		/>
	</svg>
)

export default icon
