import { IconComponentType } from '../../Icon.types'

export const TapCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 25 25'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M6.52792 12.8068C6.27459 12.6163 5.79256 12.2983 5.33708 12.2307C4.80063 12.1505 3.87867 12.5046 4.0374 13.7127C4.1456 14.5364 7.8911 18.2848 9.56785 19.9895C10.0324 20.4618 10.473 20.9604 11.0528 21.3C12.0869 21.9057 13.1728 22.1494 14.3798 22.0023C15.5959 21.8545 16.6623 21.4078 17.5292 20.5663C18.2257 19.8903 18.9248 19.2084 19.5442 18.4516C20.1759 17.6798 20.4804 16.791 20.5102 15.7977C20.5361 14.9332 20.5082 14.0687 20.4059 13.2121C20.3197 12.4948 20.1279 11.7919 20.0081 11.0804C19.8506 10.1417 19.5545 9.24633 19.3219 8.33059C19.1801 7.77222 18.6909 7.39712 18.1868 7.35245C17.5085 7.29267 17.0044 7.74003 16.8334 8.16899C16.7466 8.38709 16.7401 8.61964 16.7284 8.85547C16.7012 9.38494 16.8483 10.9845 16.8386 11.3018C16.8386 11.3018 15.9471 10.2567 15.7313 10.0373C14.6616 8.95006 13.5803 7.87535 12.5074 6.79145C12.0817 6.36117 11.001 5.15639 10.322 4.86538C9.64301 4.57437 7.84315 5.15639 8.73077 6.92283C9.22123 7.56726 9.81535 8.11381 10.3764 8.69124C10.8034 9.13137 11.2362 9.56493 11.667 10.0018C12.2048 10.5477 12.7367 11.1001 13.2855 11.6342L8.73012 6.92283L7.79067 6.07607C7.3339 5.74039 6.82466 5.6609 6.31152 5.91381C5.76016 6.18512 5.49064 6.66203 5.50619 7.28413C5.51331 7.57909 5.63317 7.84579 5.82625 8.06914C6.00442 8.27541 7.00931 9.3002 7.27883 9.57544C8.58888 10.9096 9.90282 12.2399 11.2148 13.5721L6.6886 8.96977C6.43527 8.77926 6.01802 8.46132 5.56255 8.39366C5.02609 8.31351 4.10414 8.66759 4.26287 9.87565C4.37107 10.6994 9.23224 15.6433 9.23224 15.6433"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.4397 3.35645L13.8488 4.90908"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.2087 4.2085L17.4589 5.36352"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.8946 2.71206L15.726 4.94179"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
